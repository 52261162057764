.mob-footer-wrapper {
    width: 100vw;
    background-color: #F1F2F3;
    padding: 16px 0;
    bottom: 0;
    position: fixed;
}

.mob-footer-box {
    width: calc(100vw - 16px - 16px);
    padding: 16px;
    margin: 0 16px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mob-footer-connect-wallet-button {
    width: 139px;
    height: 40px;
}

.mob-footer-blockchain-button {
    padding: 10px;
}

.mob-footer-address-button {
    width: 165px;
    height: 40px;
}

.mob-footer-amount-section {
    height: 24px;
    width: 77px;
    border-radius: 999px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mob-footer-network-icon {
    width: 20px;
    height: 20px;
}