.bridge-input-menu {
    width: 584px;
    height: 48px;
    margin: auto;
    padding: 4px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}

.bridge-input-menu-item {
    width: 288px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

@media(max-width: 1299px) {
    .bridge-input-menu {
        width: 100%;
    }

    .bridge-input-menu-item  {
        width: 49%;
    }
}

.bridge-input-menu-item-pink-badge {
    width: 24px;
    height: 24px;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#FDECFA;
    margin-left: 8px;
}