aside.svelte-rntogh {
    background: none !important;
    background-color: #f1f2f33d !important;
    z-index: 500;
}

.bn-onboard-custom.bn-onboard-modal-content.svelte-rntogh.no-padding-branding {
    border-radius: 8px !important;
    padding: 24px;
    box-shadow: 0px 16px 48px 0px #5C5C7038 !important;
}

.bn-onboard-custom.bn-onboard-modal-content-header-heading {
    font-family: 'DM Sans' !important;
    color: #2E3338 !important;
}

.bn-onboard-custom.bn-onboard-select-description {
    font-family: 'DM Sans' !important;
    color: #65657B !important;
}

.bn-onboard-custom.bn-onboard-icon-button {
    border-radius: 8px;
}

.bn-onboard-custom.bn-onboard-icon-button:hover {
    background-color: #F1F2F3 !important;
    box-shadow: none !important;
}

span.svelte-1799bj2 {
    font-family: 'DM Sans' !important;
    color: #2E3338 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 24px !important;
}

.bn-onboard-custom.bn-onboard-prepare-button.svelte-fnc3e1.cta.bn-onboard-prepare-button-center {
    border-radius: 8px !important;
    padding: 8px 16px !important;
    border: 0px !important;
    font-family: 'DM Sans' !important;
    color: #65657B !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 24px !important;
}

.bn-onboard-custom.bn-onboard-prepare-button.svelte-fnc3e1.cta.bn-onboard-prepare-button-center:hover {
    box-shadow: none !important;
    background-color: #F1F2F3 !important;
}

span.svelte-w9ftfy {
    font-family: 'DM Sans' !important;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 24px !important;
}

.bn-branding.svelte-xj304q {
    margin-top: 24px !important;
}

.light-mode-font.svelte-xj304q {
    font-family: 'DM Sans' !important;
    color: #65657B !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 16px !important;
}

.svelte-xj304q {
    margin-top: -4px !important;
}

.no-padding-branding.svelte-rntogh {
    padding-bottom: 24px !important;
}