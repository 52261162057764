.wnc-text-section {
    width: 1200px;
    margin-left: calc((100vw - 80px - 1200px)/2);
    text-align: center;
    margin-top: 186px;
}

@media(max-width: 1299px) {
    .wnc-text-section {
        width: 80%;
        margin: 30% auto;
    }
}

.wnc-connect-button {
    width: 320px;
    margin: 32px auto 0 auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width: 1299px) {
    .wnc-connect-button {
        width: 200px;
    }
}
