.pool-top-info-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
}

.pool-top-info-separator {
    width: 1px;
    height: 16px;
    background-color: #C7C7D1;
    margin: 0 24px;
}

@media(max-width: 1299px) {
    .pool-top-info-separator {
        margin: 0 8px;
        height: 32px;
    }
}

.pool-top-info-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 24px;
}

.pool-top-info-value {
    margin-left: 8px;
}

.pool-wrong-network-section {
    text-align: center;
    margin: 128px 0;
}

.pool-wrong-network-link:hover {
    cursor: pointer;
}

.pool-page-reward-card-claim-section {
    display: flex;
    justify-content: space-between;
}

.pool-page-reward-card-button {
    margin-bottom: 8px;
}

.pool-page-reward-card-button:hover {
    cursor: pointer;
}

.pool-page-reward-bullet {
    padding: 2px 0;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    margin-top: 12px;
}

.pool-page-stake-card-header {
    text-align: center;
    margin: 26px 0 0 0;
}

.pool-page-stake-card-amount {
    text-align: center;
    margin: 8px 0 24px 0;
}

.pool-page-stake-button {
    width: 100%;
    height: 40px;
}

.pool-page-stake-card-claim-info {
    width: 231px;
    text-align: center;
    margin: 24px auto 0 auto;
}

.pool-page-stake-card-claim-button:hover {
    cursor: pointer;
}