.bonds-table-b-cell {
    width: 408px;
}

.bonds-table-s-cell {
    width: 128px;
}

.bonds-table-flex-cell {
    display: flex;
    justify-content: left;
    align-items: center;
}

.bonds-table-icons-section {
    width: 48px;
}

.bonds-table-icon1, .bonds-table-icon2 {
    border-radius: 999px;
    width: 32px;
    height: 32px;
}

.bonds-table-icon2 {
    margin-left: -16px;
}

.bonds-table-text-section {
    margin-left: 12px;
} 