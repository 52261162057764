.progress {
    height: 8px !important;
    border-radius: 8px;
    background-color: #F1F2F3;;
}

.progress-bar {
    box-shadow:none;
    -webkit-box-shadow:none;
    background: linear-gradient(90deg, #FFAA99 0%, #FFE699 51.56%, #AEEAD6 100%);
}

.borrow-card-borrow-button {
    cursor: pointer;

}

.borrow-card-withdraw-button {
    cursor: pointer;
}

.borrow-card-repay-button {
    cursor: pointer;
}

.borrow-repay-method-box {
    width: 100%;
    padding: 16px;
    background-color: #F1F2F3;
    border-radius: 8px;
}

.borrow-repay-popup-content {
    margin-top: 26px;
}

.borrow-repay-method-box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.borrow-repay-selected-method-circle {
    width: 20px;
    height: 20px;
    border-radius: 999px;
    border: 6px solid #E926C3;
    cursor: pointer;
    margin-right: 8px; 
}

.borrow-repay-not-selected-method-circle {
    width: 20px;
    height: 20px;
    border-radius: 999px;
    border: 1px solid #2E3338;
    cursor: pointer;
    margin-right: 8px; 
}

.borrow-header-sect {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.borrow-repay-method-box-header-left-sect {
    display: flex;
    justify-content: left;
}

.no-tooltip {
    display: none;
}

.max-col-tooltip {
    margin-left: 314px;
    margin-top: -28px;
}

.liq-fee-tooltip {
    margin-left: 514px;
    margin-top: -28px;
}

.stab-fee-tooltip {
    margin-left: 714px;
    margin-top: -28px;
}

.liq-price-tooltip {
    margin-left: -42px;
    margin-top: -440px;
}

.asset-price-tooltip {
    margin-left: 558px;
    margin-top: -440px;
}

.pos-health-tooltip {
    margin-top: -380px;
    margin-left: -40px;
}

.small-card-tooltip {
    position: absolute;
    margin-left: -48px;
    margin-top: -8px;
}