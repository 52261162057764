.stage.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1200px;
    margin: auto;
}

@media(max-width: 1299px) {
    .stage.overlay {
        width: 100%;
    }
}

.dot-flashing.overlay {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 999px;
    background-color: #8F8FA3;
    color: #8F8FA3;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
}

.dot-flashing.overlay::before, .dot-flashing.overlay::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
.dot-flashing.overlay::before {
    left: -32px;
    width: 16px;
    height: 16px;
    border-radius: 999px;
    background-color: #8F8FA3;
    color: #8F8FA3;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}
  
.dot-flashing.overlay::after {
    left: 32px;
    width: 16px;
    height: 16px;
    border-radius: 999px;
    background-color: #8F8FA3;
    color: #8F8FA3;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
      background-color: #8F8FA3;
    }
    50%,
    100% {
      background-color: #E3E3E8;
    }
}

.loader-overlay-content {
    margin-left: -88px;
}

@media(max-width: 1299px) {
    .loader-overlay-content {
        margin-left: 0;
    }
}

.loader-overlay-info-section {
    text-align: center;
    width: 1200px;
    margin: 186px auto 0 auto;
}

@media(max-width: 1299px) {
    .loader-overlay-info-section {
        text-align: center;
        width: 100%;
        margin: 30% auto 0 auto;
    }
}

.loader-overlay-header {
    margin: 16px 0;
}

.loader-overlay-subheader {
    margin: 0 0 32px 0;
}