.portfolio-news-layout {
    margin: 16px 0 72px 0;
}

.portfolio-news-cards-section {
    display: flex;
    justify-content: space-between;
}

.portfolio-card {

}

.portfolio-card-bg-img.certik-card {

}

.portfolio-card-bg-img {
    width: 288px;
    height: 150px;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
}

.portfolio-card-bg-img:hover {
    cursor: pointer;
    box-shadow: 0px 8px 36px 0px #5C5C702B;
}

.portfolio-card-text-section {
    position: absolute;
    margin-top: -148px;
    margin-left: 16px;
}

.portfolio-card-text-section.certik-card {
    margin-top: -148px;
    margin-left: 142px;
}

.portfolio-card-update-text-section {
    position: absolute;
    margin-top: -148px;
    margin-left: 132px;
    width: 139px;
}

.portfolio-card-update-text-section.crv-card {
    margin-left: 148px;
}

.portfolio-card-button {
    width: 51px;
    height: 24px;
    text-align: center;
    margin-bottom: 8px;
}

.portfolio-elipses-section {
    width: 200px;
    margin: 32px auto;
    display: flex;
    justify-content: space-between;
}

@media(max-width: 1299px) {
    .portfolio-elipses-section {
        margin-top: 48px;
    }
}

.portfolio-elipses-w-section {
    width: 50px;
    margin: 32px auto;
    display: flex;
    justify-content: space-between;
}

.portfolio-selected-elipse {
    width: 12px;
    height: 12px;
    background-color: #65657B;
    border-radius: 999px;
}

.portfolio-elipse {
    width: 12px;
    height: 12px;
    background-color: #C7C7D1;
    border-radius: 999px;
}

@media(max-width: 1288px) {
    .portfolio-card-bg-img {
       width: 100%;
       height: auto;
    }
}

.portfolio-elipse:hover, .portfolio-selected-elipse:hover {
    cursor: pointer;
}

.portfolio-card-assets-section {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 30px;
}

.portfolio-card-asset-icon {
    width: 24px;
    height: 24px;
    border-radius: 999px;
}

.portfolio-card-asset-icon.second-icon {
    margin-left: -12px;
}

.portfolio-gen-info-cards-section {
    margin: 24px 0;
}

@media(min-width: 1300px) {
    .portfolio-gen-info-cards-section {
        display: flex;
        justify-content: space-between;
    }
}

.portfolio-gen-info-card {
    width: 389.33px;
    height: 140px;
    padding: 24px;
    border-radius: 8px;
}

@media(max-width: 1299px) {
    .portfolio-gen-info-card {
        width: 100%;
        margin-bottom: 16px;
    }
}

.portfolio-gen-info-card-value-section {
    display: flex;
    justify-content: left;
    margin-top: 16px;
}

.portfolio-gen-info-card-primary-value {
    margin-right: 8px;
}

.portfolio-gen-info-card-links-section {
    display: flex;
    justify-content: left;
    margin-top: 28px;
}

.portfolio-gen-info-card-link {
    cursor: pointer;
    margin-right: 16px;
}

.portfolio-position-card {
    width: 389.33px;
    border-radius: 8px;
    padding: 24px;
    margin-right: 16px;
}

@media(max-width: 1299px) {
    .portfolio-position-card {
        width: 100%;
        margin: 16px 0;
    }
}

s-section-header {
    margin: 24px 0 16px;
}

.portfolio-position-cards-section {
    margin: 0 0 24px 0;
}

@media(min-width: 1300px) {
    .portfolio-position-cards-section {
        display: flex;
        justify-content: left;
    }
}

.portfolio-position-card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}

.portfolio-position-new-line {
    
}

.portfolio-position-card-header-left-section {
    display: flex;
    justify-content: left;
    align-items: center;
}

.portfolio-position-card-header-coll-icon {
    width: 48px;
    height: 48px;
    margin-right: 12px;
}

.portfolio-position-card-header-asset-icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
}

.portfolio-bullet-tag {
    background: linear-gradient(90deg, #FDECFA 0%, #FFEBF1 100%);
    height: 24px;
    border-radius: 999px;
    padding: 4px 8px;
}

.portfolio-link-button {
    width: 100%;
    height: 40px;
    padding: 8px 0;
    margin-top: 18px;
}

.portfolio-open-pos-card-buttons-section {
    padding: 24px 0 0 0;
    border-top: 1px solid #F1F2F3;
    display: flex;
    justify-content: left;
    align-items: center;
}

.portfolio-position-cards-section-header {
    margin: 48px 0 16px 0;
}

.portfolio-card-pool-asset-icons-section {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 4px;
}

.portfolio-card-pool-asset-icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}