.header-wrapper {
    padding: 16px 0;
    margin-left: 80px;
}

.header-wrapper-on-scroll {
    width: 100%;
    position: fixed;
    background-color: #f1f2f361;
    margin-left: 80px;
    padding: 16px 0;
    margin-top: -32px;
    z-index: 3;
}

.header-content {
    
}

.header-content-on-scroll {
    padding: 16px;
    width: 1200px;
    background-color: white;
    position: fixed;
    left: calc((100% - 1200px + 80px)/2);
    right: calc((100% - 1200px + 80px)/2);
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
    animation-name: headerAnimation;
    animation-duration: 1s;
  }
  
  @keyframes headerAnimation {
    0%   {top:0px;}
    /* 50%  {top:16px;} */
    100%  {top:16px;}
  }

.header-layout {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.header-layout-with-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-back-button {
    background-color: white;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:1000;
}

.header-back-button-on-scroll {
    background-color: #F1F2F3;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-right-section {
    display: flex;
    justify-content: right;
}

.header-blockchain-button {
    background-color: white;
    padding: 10px;
}

.header-blockchain-button-on-scroll {
    background-color: #F1F2F3;
    padding: 10px;
}

.header-blockchain-button-icon {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-connect-wallet-button {
    padding: 8px 16px;
    margin-left: 16px;
}

.header-address-button {
    background-color: white;
    width: 165px;
    margin-left: 16px;
    height: 40px;
}

.header-address-button-on-scroll {
    background-color: #F1F2F3;
    width: 165px;
    margin-left: 16px;
    height: 40px;
}

.header-amount-section {
    background-color: #F1F2F3;
    height: 24px;
    width: 77px;
    border-radius: 999px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-amount-section-on-scroll {
    background-color: white;
    height: 24px;
    width: 77px;
    border-radius: 999px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-address-section {
    margin-left: 8px;
}

.header-back-button-text {
    margin-left: 8px;
    opacity: 1 !important;
}

.header-back-button {
    width: 95px;
    height: 40px;
}

.header-back-button:hover{
    
}

.blockchain-dropdown-row:hover {
    background-color: #F1F2F3;
    border-radius: 8px;
}

.header-network-icon {
    width: 20px;
    height: 20px;
}

.mob-header-wrapper {
    width: 100vw;
    background-color: #F1F2F3;
    padding: 16px 0;
}

.mob-header-box {
    width: calc(100vw - 16px - 16px);
    left: 16px;
    right: 16px;
    padding: 16px;
    border-radius: 8px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
}

.mob-header-dropdown-box {
    width: calc(100vw - 16px - 16px);
    top: 72px;
    left: 16px;
    right: 16px;
    padding: 16px;
    border-radius: 0px 0px 8px 8px;
    position: fixed;
    border-top: 1px solid #F1F2F3;
    z-index: 2;
}

.mob-header-dropdown-row {
    padding: 12px 0;
    display: flex;
    justify-content: left;
    align-items: center;
}

.mob-header-dropdown-menu-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}