
.no-tooltip {
    display: none;
}

.max-col-tooltip {
    margin-left: 314px;
    margin-top: -28px;
}

.liq-fee-tooltip {
    margin-left: 514px;
    margin-top: -28px;
}

.stab-fee-tooltip {
    margin-left: 714px;
    margin-top: -28px;
}

.liq-price-tooltip {
    margin-left: -42px;
    margin-top: -440px;
}

.asset-price-tooltip {
    margin-left: 558px;
    margin-top: -440px;
}

.pos-health-tooltip {
    margin-top: -380px;
    margin-left: -40px;
}

.small-card-tooltip {
    position: absolute;
    margin-left: -48px;
    margin-top: -8px;
}