.stab-single-card-left-side {
    width: 292px;
    margin-right: 16px;
}

@media(max-width: 1299px) {
    .stab-single-card-left-side {
        width: 100%;
        margin-right: 0;
    }
}

.stab-card-info-icon {
    cursor: pointer;
}

.fee-div {
    margin-top: 8px;
    text-align: right;
}

.stabilizer-stablecoins-dropdown {
    position: absolute;
    width: 220px;
    padding: 8px;
    z-index: 1;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
    margin-top: 8px;
}

@media(max-width: 1299px) {
    .stabilizer-stablecoins-dropdown {
        width: auto;
    }
}

.stab-results-box {
    width: 100%;
    height: 64px;
    border: 1px solid #E3E3E8;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}

.stab-results-box-left-section {
    width: 425px;
    height: 100%;
    padding: 16px;
    border-radius: 8px 0px 0px 8px;
}

.stab-results-box-right-section {
    width: 111px;
    height: 100%;
    padding: 16px;
    border-radius: 0px 8px 8px 0px;
}

.stab-results-box-right-section-box {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
}

.stab-results-box-right-section-icon {
    width: 20px;
    height: 20px;
}

.stab-switch-icon-section {
    text-align: center;
    margin: 32px auto 0 auto;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stab-switch-icon-section:hover {
    cursor: pointer;
    border-radius: 999px;
    background-color: #f7f7f7;
}

.stab-results-box-left-section2 {
    width: 399px;
    height: 100%;
    padding: 16px;
    border-radius: 8px 0px 0px 8px;
}

.stab-results-box-right-section2 {
    width: 137px;
    height: 100%;
    padding: 16px;
    border-radius: 0px 8px 8px 0px;
}

.stab-asset-dropdown2 {
    
}

.stabilizer-tutorial-link-section {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 8px;
}