@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
aside.svelte-rntogh {
    background: none !important;
    background-color: #f1f2f33d !important;
    z-index: 500;
}

.bn-onboard-custom.bn-onboard-modal-content.svelte-rntogh.no-padding-branding {
    border-radius: 8px !important;
    padding: 24px;
    box-shadow: 0px 16px 48px 0px #5C5C7038 !important;
}

.bn-onboard-custom.bn-onboard-modal-content-header-heading {
    font-family: 'DM Sans' !important;
    color: #2E3338 !important;
}

.bn-onboard-custom.bn-onboard-select-description {
    font-family: 'DM Sans' !important;
    color: #65657B !important;
}

.bn-onboard-custom.bn-onboard-icon-button {
    border-radius: 8px;
}

.bn-onboard-custom.bn-onboard-icon-button:hover {
    background-color: #F1F2F3 !important;
    box-shadow: none !important;
}

span.svelte-1799bj2 {
    font-family: 'DM Sans' !important;
    color: #2E3338 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 24px !important;
}

.bn-onboard-custom.bn-onboard-prepare-button.svelte-fnc3e1.cta.bn-onboard-prepare-button-center {
    border-radius: 8px !important;
    padding: 8px 16px !important;
    border: 0px !important;
    font-family: 'DM Sans' !important;
    color: #65657B !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 24px !important;
}

.bn-onboard-custom.bn-onboard-prepare-button.svelte-fnc3e1.cta.bn-onboard-prepare-button-center:hover {
    box-shadow: none !important;
    background-color: #F1F2F3 !important;
}

span.svelte-w9ftfy {
    font-family: 'DM Sans' !important;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 24px !important;
}

.bn-branding.svelte-xj304q {
    margin-top: 24px !important;
}

.light-mode-font.svelte-xj304q {
    font-family: 'DM Sans' !important;
    color: #65657B !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 16px !important;
}

.svelte-xj304q {
    margin-top: -4px !important;
}

.no-padding-branding.svelte-rntogh {
    padding-bottom: 24px !important;
}
/* Typography */
.styles_displayXL__3YH_2 {
    font-family: 'DM Sans', sans-serif;
    font-size: 160px;
    font-weight: 400;
    line-height: 160px;
}

.styles_displayL__3WZzr {
    font-family: 'DM Sans', sans-serif;
    font-size: 100px;
    font-weight: 400;
    line-height: 100px;
}

.styles_displayM__1AUIF {
    font-family: 'DM Sans', sans-serif;
    font-size: 72px;
    font-weight: 700;
    line-height: 72px;
}

.styles_headingXXXL__3cMlg {
    font-family: 'DM Sans', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 54px;
}

.styles_headingXXL__2eT-k {
    font-family: 'DM Sans', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
}

.styles_headingXL__1cVDB {
    font-family: 'DM Sans', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
}

.styles_headingL__3S6xZ {
    font-family: 'DM Sans', sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
}

.styles_headingM__suB9M {
    font-family: 'DM Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
}

.styles_headingS__3Hjsx {
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.styles_headingXS__2MwVK {
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.styles_paragraphXL__2XHnw {
    font-family: 'DM Sans', sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
}

.styles_paragraphL__25pOe {
    font-family: 'DM Sans', sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
}

.styles_paragraphM__2_Q4S {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}

.styles_paragraphS__G4DJI {
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
}

.styles_paragraphXS__3ys-7 {
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.styles_labelLRegular__21Zno {
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}

.styles_labelLBold__1_xOR {
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.styles_labelMRegular__2tEKj {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}

.styles_labelMBold__15Km8 {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
}

.styles_labelSRegular__3iDAt {
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
}

.styles_labelSBold__2Jofs {
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
}

.styles_labelXSRegular__3rcz- {
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.styles_labelXSBold__YNt_v {
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

/* Colors */
.styles_magentaText1__3z_Gx {
    color: #E926C3;
}

.styles_pinkText2__2aRdh {
    color: #FF4D86;
}

.styles_magentaBg1__38qW1 {
    background-color: #E926C3;
}

.styles_pinkBg2__2akPJ {
    background-color: #FF4D86;
}

.styles_gradientText__JDZ36 {
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.styles_gradientText__JDZ36:hover {
    background: linear-gradient(90deg, #EB3DC9 0%, #FF6697 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.styles_gradientBg__1pJRP {
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
}

.styles_gradientBg__1pJRP:hover {
    background: linear-gradient(90deg, #EB3DC9 0%, #FF6697 100%);
}

.styles_succesText__GZrlL {
    color: #34C796;
}

.styles_succesText__GZrlL:hover {
    color: #2EB286;
}

.styles_succesBg__1nwwf {
    background: #EBFAF5;
}

.styles_warningText__1V3uc {
    color: #FF8000;
}

.styles_warningText__1V3uc:hover {
    color: #E57300;
}

.styles_warningBg__35eA8 {
    background-color: #FFF5EB;
}

.styles_dangerText__2IbPX {
    color: #FF5330;
}

.styles_dangerText__2IbPX:hover {
    color: #FF5330;
}

.styles_dangerBg__23WN8 {
    background-color: #FFEEEB;
}

.styles_infoText__35dbs {
    color: #0097C9;
}

.styles_infoText__35dbs:hover {
    color: #0082AD;
}

.styles_infoBg__2lYfM {
    background-color: #E5F9FF;
}

.styles_primaryText__2POIQ {
    color: #2E3338;
}

.styles_secondaryText__3x0Wv {
    color: #65657B;
}

.styles_disabledText__syBsx {
    color: #8F8FA3;
}

.styles_disabledBg__1R4QI {
    background-color: #5C5C70;
    opacity: 0.32;
}

.styles_bg__3mkh0 {
    background-color: #F1F2F3;
}

.styles_whiteText__1VnS7 {
    color: #FFF;
}

.styles_whiteBg__fjM6q {
    background-color: #FFF !important;
}

.styles_transparentBg__1t0VT {
    background-color: #FFF;
    opacity: 0.32;
}

.styles_elevationLow__31DDg {
    box-shadow: 0px 4px 16px 0px #5C5C701F;
}

.styles_elevationMedium__RPXNk {
    box-shadow: 0px 8px 36px 0px #5C5C702B;
}

.styles_elevationHigh__4GIiu {
    box-shadow: 0px 16px 48px 0px #5C5C7038;
}

.styles_wrapper__zFyMV {
    margin-left: 80px;
    padding-bottom: 128px;
}

@media(max-width: 1299px) {
    .styles_wrapper__zFyMV {
        margin-left: 0;
        padding-bottom: 0;
        max-height: 100vh;
        width: 100vw;
        overflow-y: scroll;
        margin-top: 64px;
        margin-bottom: 64px;
    }
}

.styles_content__1GpZC {
    width: 1200px;
    margin: auto;
}

@media(max-width: 1299px) {
    .styles_content__1GpZC {
        width: calc(100vw - 16px - 16px);
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 0;
        margin-bottom: 72px;
    }
}

.styles_button__1q9Eo {
    border-radius: 8px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.styles_button__1q9Eo:hover {
    cursor: pointer;
}

.styles_body__3atIk {
    min-height: 100vh;
    width: 100%;
    background-color: #F1F2F3;
    background: linear-gradient(90deg, rgba(241,242,243,1) 4%, rgba(251, 218, 245, 0.7) 25%, rgba(254,226,219,0.7) 49%, rgba(251,251,218,0.7) 77%, rgba(241,242,243,1) 100%);
}

@media(max-width: 1299px) {
    .styles_body__3atIk {
        min-height: 100vh;
        width: 100%;
        background-color: #F1F2F3;
        background: linear-gradient(90deg, #F1F2F3 1%, #F1F2F3 100%);
    }
}

.styles_headerSection__3IL8U {
    width: 100%;
    height: 86px;
    margin: 32px 0;
    padding: 0 0 16px 0;
    text-align: center;
}

@media(max-width: 1299px) {
    .styles_headerSection__3IL8U {
        height: auto;
    }
}

.styles_popupWrapper__1x-G_ {
    position: fixed;
    top: 72px;
    left: 80px;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

@media(max-width: 1299px) {
    .styles_popupWrapper__1x-G_ {
        left: 0;
        bottom: 100px;
        top: calc(72px + 8px);
        height: calc(100vh - 100px - 72px - 8px);
    }
}

.styles_popupBox__rGQsU {
    
}

@media(min-width: 1300px) {
    .styles_layout__2ikHx {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }
}

.styles_leftSection__1ZSWn {
    width: 738px;
    margin-right: 16px;
}

@media(min-width: 1300px) {
    .styles_leftSection__1ZSWn {
        -webkit-animation-name: styles_leftSectionAnimation__Qf1wp;
                animation-name: styles_leftSectionAnimation__Qf1wp;
        -webkit-animation-duration: 0.75s;
                animation-duration: 0.75s;
    }
}

@media(max-width: 1299px) {
    .styles_leftSection__1ZSWn {
        width: 100%;
    }
}

@-webkit-keyframes styles_leftSectionAnimation__Qf1wp {
    0%   {margin-right: 8px; margin-top: -10px}
    100% {margin-right: 16px; margin-top: 0px}
}

@keyframes styles_leftSectionAnimation__Qf1wp {
    0%   {margin-right: 8px; margin-top: -10px}
    100% {margin-right: 16px; margin-top: 0px}
}

.styles_greyBg__23Yfz {
    background-color: #E3E3E8;
}

@media(min-width: 1300px) {
    .styles_cardsRow__e_HUd {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        margin-bottom: 16px;
    }
}

.styles_card__3eb4P {
    width: 361px;
    height: 148px;
    border-radius: 8px;
    padding: 24px;
}

@media(max-width: 1299px) {
    .styles_card__3eb4P {
        width: 100%;
        margin-bottom: 16px;
    }
}

.styles_cardFullDim__3hCcJ {
    width: 738px;
    height: 148px;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 16px;
}

@media(max-width: 1299px) {
    .styles_cardFullDim__3hCcJ {
        width: 100%;
    }
}

.styles_cardFullDimRow__1b-UU {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.styles_cardProgressBar__3JBXf {
    margin: 28px 0 4px 0;
}

.styles_cardValueSection__28nN8 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    margin-top: 4px;
}

.styles_secondValue__1o5Qb {
    margin-left: 8px;
}

.styles_cardBullet__pZOFO {
    padding: 2px 4px;
    border-radius: 999px;
    display: inline-block;;
    -webkit-justify-content: center;;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 38px;
}

.styles_rightSection__f6s2o {
    width: 430px;
    margin-left: 16px;
}

@media(min-width: 1300px) {
    .styles_rightSection__f6s2o {
        -webkit-animation-name: styles_rightSectionAnimation__1XPmc;
                animation-name: styles_rightSectionAnimation__1XPmc;
        -webkit-animation-duration: 0.75s;
                animation-duration: 0.75s;
    }
}

@media(max-width: 1299px) {
    .styles_rightSection__f6s2o {
        width: 100%;
        margin-left: 0;
    }
}

@-webkit-keyframes styles_rightSectionAnimation__1XPmc {
    0%   {margin-left: 8px; margin-top: -10px}
    100% {margin-left: 16px; margin-top: 0px}
}

@keyframes styles_rightSectionAnimation__1XPmc {
    0%   {margin-left: 8px; margin-top: -10px}
    100% {margin-left: 16px; margin-top: 0px}
}

.styles_inputCard__m6uBh {
    width: 100%;
    padding: 24px;
    border-top: 1px solid #E3E3E8;
    border-radius: 0 0 8px 8px;
}

@media(max-width: 1299px) {
    .styles_inputCard__m6uBh {
        margin-bottom: 16px;
        position: relative;
    }
}

.styles_inputCardHeader__OnS3l {
    display: -webkit-flex;
    display: flex;
}

.styles_inputMenu__z01ac {
    width: 100%;
    height: 48px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.styles_inputMenuItem__T6yC5 {
    width: 50%;
    height: 100%;
    padding: 12px 0;
    border-radius: 8px 0 0 0;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.styles_inputMenuItem__T6yC5:hover {
    cursor: pointer;
}

.styles_inputCardIcon__19DyJ:hover {
    cursor: pointer;
}

.styles_inputCardTitle__3ShhO {
    width: 310px;
    margin: 0 36px 0 16px;
    text-align: center;
}

.styles_inputHeader__uPGVN {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 26px 0 8px 0;
}

.styles_inputBalanceSection__2p0Pw, .styles_inputBalanceSectionGrad__2Xy_X {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.styles_inputBalanceSectionGrad__2Xy_X:hover {
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

.styles_inputBalance__3CuAB {
    margin-left: 4px;
}

.styles_inputBox__3zWzh {
    width: 100%;
    padding: 16px;
    border: 2px solid #F1F2F3;
    border-radius: 8px;
}

.styles_inputBoxInactive__VHXs9 {
    width: 100%;
    padding: 16px;
    border: 1px solid #E3E3E8;
    border-radius: 8px;
    margin-top: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.styles_flexInputBox__1P3Wx {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.styles_inputBox__3zWzh:hover{
    border: 2px solid #E926C3;
}

.styles_inputButton__2pE6K {
    height: 48px;
    margin: 24px 0 0 0;
}

input {
    width: 100% !important;
    border: 1px solid white;
    background-color: white !important;
    font-family: DM Sans !important;
    color: #2E3338;
}

input:focus-visible {
    border: 0px !important;
    outline: 0px !important;
}

.styles_orderDetailsSection__8V-Tc {
    margin-top: 24px;
    margin-bottom: 6px;
}

.styles_orderDetailsInfoBox__2utp1 {
    width: 100%;
    background-color: #F1F2F3;
    border-radius: 8px;
    padding: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 12px 0;
}

.styles_orderDetailsInfoIcon__3Lr-O {
    margin-top: -5px;
    margin-right: 8px;
}

.styles_orderDetailsRow__1x9hp, .styles_orderDetailsSubRow__1F747 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 6px 0;
}

orderDetailsSubRow {
    margin-left: 12px;
}

.styles_orderDetailsSubRow__1F747 {
    margin-left: 12px;
}

.styles_orderDetailsFeesRow__1_DSu {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
            justify-content: right;
}

.styles_orderDetailsFeesIcon__Lnt7- {
    margin-top: -6px;
}

.styles_orderDetailsFeesIcon__Lnt7-:hover {
    cursor: pointer;
}

.styles_subheaderSection__Z7hnr {
    margin-top: 16px;
}

@media(min-width: 1300px) {
    .styles_subheaderSection__Z7hnr {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        justify-items: center;
    }
}

/* @media(max-width: 1299px) { */
    .styles_mobSubheaderLinkSection__nXhjU {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        justify-items: center;
        margin-top: 16px;
    }
/* } */

@media(min-width: 1299px) {
    .styles_mobSubheaderLinkSection__nXhjU {
        margin-top: 0;
    }
}

.styles_subheaderMiddle__2L30E {
    margin: 0 8px 0 16px;
}

.styles_table__3gKBs {
    width: 100%;
    margin: auto;
    -webkit-animation-name: styles_tableAnimation__3KuZg;
            animation-name: styles_tableAnimation__3KuZg;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}

@-webkit-keyframes styles_tableAnimation__3KuZg {
    0%   {width:90%}
    100% {width:100%}
}

@keyframes styles_tableAnimation__3KuZg {
    0%   {width:90%}
    100% {width:100%}
}

.styles_tableHeader__1Nt_P {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 6px;
    padding: 0 16px;
}

.styles_tableRow__6nk69 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px 16px;
    border-radius: 8px;
    background-color: white;
    margin: 4px 0;
}

.styles_tableRow__6nk69:hover {
    cursor: pointer;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
    z-index: 2;
    position: relative;
}

.styles_dropdownButton__2xEwq {
    padding: 6px 8px;
}

.styles_dropdownAssetIcon__GuoXL {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}

.styles_poolAssetDropdown__ROK-I {
    position: absolute;
    display: block;
    z-index: 1;
    width: 220px;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
    margin-left: 128px;
    margin-top: 220px;
}

@media(max-width: 1299px) {
    .styles_poolAssetDropdown__ROK-I {
        margin-left: 80px;
    }
}

.styles_poolAssetDropdownRow__2RRd6 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
    height: 40px;
    width: 100%;
    border-radius: 8px;
    padding: 0 16px;
}

.styles_poolAssetDropdownRow__2RRd6:hover {
    background-color: #F1F2F3;
    cursor: pointer;
}

.styles_poolAssetDropdownIcon__3Hbcv {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

@media(min-width: 1300px) {
    .styles_singleCardLayout__3SQia {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: left;
                justify-content: left;
    }
}

.styles_singleSCard__3KFff {
    width: 584px;
    border-radius: 8px;
    padding: 24px;
}

@media(max-width: 1299px) {
    .styles_singleSCard__3KFff {
        width: 100%;
    }
}

.styles_singleCardHeader__m2fzn {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.styles_infoCard__1zuq_ {
    width: 100%;
    border-radius: 8px;
    padding: 24px;
}

@media(max-width: 1299px) {
    .styles_infoCard__1zuq_ {
        margin-bottom: 16px;
    }
}

.styles_mobTableCardHeader__2wwEY {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 24px;
}

.styles_mobTableCardHeaderLeftSection__rui0o {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.styles_mobTableCardHeaderRightSection__2LD6T {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
            justify-content: right;
    -webkit-align-items: center;
            align-items: center;
}

.styles_mobTableCardRow__30TYv {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 10px 0;
}

.styles_rangeInput___Nd8h {
    margin: 32px 0 0 0;
}

input[type="range"] {
    background-color: #F1F2F3 !important;
    border-radius: 999px;
}
  
input[type="range"]::-webkit-slider-thumb {
    
}

.styles_cardBulletArrowIcon__38Xsb {
    width: 16px;
    height: 16px;
    margin: -2px 4px 0px 4px;
}

input[type="range"]::-moz-range-progress {
    
  }
  input[type="range"]::-moz-range-track {  
    
  }
  /* IE*/
  input[type="range"]::-ms-fill-lower {
 
  }
  input[type="range"]::-ms-fill-upper {  
    
  }

  input[type='range'] {
    overflow: hidden;
    width: 80px;
    -webkit-appearance: none;
    
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 15px;
    -webkit-appearance: none;
    margin-top: -1px;
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    height: 15px;
    border-radius: 999px;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
    box-shadow: -382px 0 0 374px #E926C3;
    cursor: pointer;
    border: 2px solid #FFF;
  }
.blockchain-dropdown-wrapper {
    width: 100%;
    height: 100%;
    margin-left: 80px;
    position: fixed;
    z-index: 2;
}

@media(max-width: 1299px) {
    .blockchain-dropdown-wrapper {
        margin-left: 0;
    }
}

.blockchain-dropdown-layout {
   display: -webkit-flex;
   display: flex;
   -webkit-justify-content: right;
           justify-content: right;
   margin-right: calc(80px + (100% - 80px - 1200px)/2 + 165px + 16px);
}

@media(max-width: 1299px) {
    .blockchain-dropdown-layout {
        margin-right: 0;
        bottom: 100px;
        left: calc(100vw - 16px - 272px);
        position: fixed;
    }
}

.blockchain-dropdown {
    width: 272px;
    padding: 8px;
    border-radius: 8px;
}

@-webkit-keyframes blockchainDropdownAnimation {
    0%   {margin-top:-16px;}
    50%  {margin-top:-8px;}
    100% {margin-top:-8px;}
}

@keyframes blockchainDropdownAnimation {
    0%   {margin-top:-16px;}
    50%  {margin-top:-8px;}
    100% {margin-top:-8px;}
}

.blockchain-dropdown-on-scroll {
    width: 272px;
    padding: 8px;
    border-radius: 8px;
    margin-top: 64px;
    margin-right: 16px;
}

@-webkit-keyframes blockchainDropdownAnimationOnScroll {

}

@keyframes blockchainDropdownAnimationOnScroll {

}

.blockchain-dropdown-row {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 16px;
}

.blockchain-dropdown-row:hover {
    cursor: pointer;
}

.blockchain-dropdown-row-icon {
    width: 20px;
    height: 20px;
}

.blockchain-dropdown-row-text {
    margin-left: 12px;
}
.disconnect-dropdown-wrapper {
    width: 100%;
    height: 100%;
    margin-left: 80px;
    position: fixed;
    z-index: 100;
}

.disconnect-dropdown-close-wrapper {
    width: 100%;
    height: 100%;
    margin-left: 80px;
    position: fixed;
}

@media(max-width: 1299px) {
    .disconnect-dropdown-wrapper {
        margin-left: 0;
    }
}

.disconnect-dropdown-layout {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
            justify-content: right;
    margin-right: calc(80px + (100% - 80px - 1200px)/2);
}

@media(max-width: 1299px) {
    .disconnect-dropdown-layout {
        margin-right: 0;
        left: 16px;
        bottom: 100px;
        position: fixed;
    }
}

.disconnect-dropdown {
    position: absolute;
    width: 288px;
    padding: 18px 24px;
    border-radius: 8px;
    right: calc(80px + (100% - 80px - 1200px)/2);
    z-index: 100000;
}

@media(max-width: 1299px) {
    .disconnect-dropdown {
        left: 0;
        bottom: 0px;
    }
}

.disconnect-dropdown-on-scroll {
    width: 272px;
    padding: 18px 24px;
    border-radius: 8px;
    margin-top: 64px;
    margin-right: 16px;
}

.disconnect-dropdown-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #F1F2F3;
}

.disconnect-dropdown-header-left-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.disconnect-dropdown-header-left-section-text-section {
    margin-left: 8px;
}

.disconnect-dropdown-slippage-section {
    padding: 16px 0;
    border-bottom: 1px solid #F1F2F3;
}

.disconnect-dropdown-slippage-title {
    margin-bottom: 8px;
}

.disconnect-dropdown-slippage-subtitle {
    margin: 8px 0 0 0;
    text-align: right;
}

.disconnect-dropdown-slippage-input-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.disconnect-dropdown-slippage-input-label, .disconnect-dropdown-slippage-text-input-label {
    width: 80px;
    height: 32px;
    border-radius: 999px;
    border: 1px solid #F1F2F3;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    margin: 0 0 0 8px;
}

@media(max-width: 1299px) {
    .disconnect-dropdown-slippage-input-label, .disconnect-dropdown-slippage-text-input-label {
        width: 60px;
        margin: 0 0 0 2px;
    }
}

.disconnect-dropdown-slippage-input-label:hover {
    background-color: #F1F2F3;
}

.disconnect-dropdown-add-tokens-section {
    padding: 16px 0;
    border-bottom: 1px solid #F1F2F3;
}

.disconnect-dropdown-add-token-buttons-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin: 16px 0;
}

.disconnect-dropdown-add-token-button {
    width: 110px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #E926C3;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.disconnect-dropdown-add-token-button:hover {
    cursor: pointer;
    border: 1px solid #FF4D86;
}

.disconnect-button {
    padding: 16px 0 0 0;
    cursor: pointer;
}

.disconnect-button-layout {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
}

.disconnect-button-text {
    margin-left: 8px;
}
.stage {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-left: 16px;
  }

.dot-flashing {
    position: relative;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #8F8FA3;
    color: #8F8FA3;
    -webkit-animation: dotFlashing 1s infinite linear alternate;
            animation: dotFlashing 1s infinite linear alternate;
    -webkit-animation-delay: .5s;
            animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
.dot-flashing::before {
    left: -10px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #8F8FA3;
    color: #8F8FA3;
    -webkit-animation: dotFlashing 1s infinite alternate;
            animation: dotFlashing 1s infinite alternate;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}
  
.dot-flashing::after {
    left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #8F8FA3;
    color: #8F8FA3;
    -webkit-animation: dotFlashing 1s infinite alternate;
            animation: dotFlashing 1s infinite alternate;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

@-webkit-keyframes dotFlashing {
    0% {
      background-color: #8F8FA3;
    }
    50%,
    100% {
      background-color: #E3E3E8;
    }
}

@keyframes dotFlashing {
    0% {
      background-color: #8F8FA3;
    }
    50%,
    100% {
      background-color: #E3E3E8;
    }
}
.account-overlay-wrapper {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: #f1f2f370;
}

.account-overlay-close-wrapper {
    width: 100%;
    height: 100%;
    right: 512px;
    position: fixed;
    cursor: pointer;
}

.account-overlay-layout {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
            justify-content: right;
    -webkit-align-items: right;
            align-items: right;
}

.account-overlay-sidebar {
    width: 512px;
    height: 100vh;
    background-color: #FFF;
    padding: 32px 24px;
    -webkit-animation-name: accountOverlaySidebarAnimation;
            animation-name: accountOverlaySidebarAnimation;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
}

@media(max-width: 1299px) {
    .account-overlay-sidebar {
        width: 95%;
        margin: 100px auto 0 auto;
        height: 100%;
        border-radius: 8px;
        -webkit-animation: none;
                animation: none;
    }
}

@-webkit-keyframes accountOverlaySidebarAnimation {
    0%   {margin-right: -400px};
    50%  {margin-right: 20px;}
    100% {margin-right: 0px;}
}

@keyframes accountOverlaySidebarAnimation {
    0%   {margin-right: -400px};
    50%  {margin-right: 20px;}
    100% {margin-right: 0px;}
}

.account-sidebar-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.account-sidebar-section {
    width: 100%;
    border: 1px solid #F1F2F3;
    border-radius: 8px;
    margin: 24px 0;
    padding: 24px;
}

.account-sidebar-subsection {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid #F1F2F3;
}

.account-sidebar-address-box {
    background-color: #F1F2F3;
    padding: 8px 12px;
    border-radius: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.account-sidebar-address-box-metamask-icon {
    margin-right: 8px;
}

.account-sidebar-address-box-copy-icon {
    width: 20px;
    height: 20px;
    margin: 0 8px;
}

.account-sidebar-address-box-link-icon {
    width: 20px;
    height: 20px;
}

.account-sidebar-address-box-copy-icon:hover,
.account-sidebar-address-box-link-icon:hover {
    cursor: pointer;
    opacity: 0.8;
}

.account-sidebar-subsection {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.account-sidebar-subsection-left {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.account-sidebar-subsection-right:hover {
    cursor: pointer;
    opacity: 0.8;
}

.account-sidebar-subsection-header {
    margin: 16px 0;
}

.account-sidebar-subsection-row {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin: 12px 0;
}

.account-sidebar-subsection-row-left {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.account-sidebar-asset-icon {
    width: 32px;
    height: 32px;
    margin: 0 8px 0 0;
    border-radius: 999px;
}

.account-overlay-balance {
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.account-overlay-add-button {
    cursor: pointer;
}

.account-sidebar-slip-sett {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.header-wrapper {
    padding: 16px 0;
    margin-left: 80px;
}

.header-wrapper-on-scroll {
    width: 100%;
    position: fixed;
    background-color: #f1f2f361;
    margin-left: 80px;
    padding: 16px 0;
    margin-top: -32px;
    z-index: 3;
}

.header-content {
    
}

.header-content-on-scroll {
    padding: 16px;
    width: 1200px;
    background-color: white;
    position: fixed;
    left: calc((100% - 1200px + 80px)/2);
    right: calc((100% - 1200px + 80px)/2);
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
    -webkit-animation-name: headerAnimation;
            animation-name: headerAnimation;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
  
  @-webkit-keyframes headerAnimation {
    0%   {top:0px;}
    /* 50%  {top:16px;} */
    100%  {top:16px;}
  }
  
  @keyframes headerAnimation {
    0%   {top:0px;}
    /* 50%  {top:16px;} */
    100%  {top:16px;}
  }

.header-layout {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
            justify-content: right;
    -webkit-align-items: center;
            align-items: center;
}

.header-layout-with-button {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.header-back-button {
    background-color: white;
    padding: 8px 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index:1000;
}

.header-back-button-on-scroll {
    background-color: #F1F2F3;
    padding: 8px 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.header-right-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
            justify-content: right;
}

.header-blockchain-button {
    background-color: white;
    padding: 10px;
}

.header-blockchain-button-on-scroll {
    background-color: #F1F2F3;
    padding: 10px;
}

.header-blockchain-button-icon {
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.header-connect-wallet-button {
    padding: 8px 16px;
    margin-left: 16px;
}

.header-address-button {
    background-color: white;
    width: 165px;
    margin-left: 16px;
    height: 40px;
}

.header-address-button-on-scroll {
    background-color: #F1F2F3;
    width: 165px;
    margin-left: 16px;
    height: 40px;
}

.header-amount-section {
    background-color: #F1F2F3;
    height: 24px;
    width: 77px;
    border-radius: 999px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.header-amount-section-on-scroll {
    background-color: white;
    height: 24px;
    width: 77px;
    border-radius: 999px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.header-address-section {
    margin-left: 8px;
}

.header-back-button-text {
    margin-left: 8px;
    opacity: 1 !important;
}

.header-back-button {
    width: 95px;
    height: 40px;
}

.header-back-button:hover{
    
}

.blockchain-dropdown-row:hover {
    background-color: #F1F2F3;
    border-radius: 8px;
}

.header-network-icon {
    width: 20px;
    height: 20px;
}

.mob-header-wrapper {
    width: 100vw;
    background-color: #F1F2F3;
    padding: 16px 0;
}

.mob-header-box {
    width: calc(100vw - 16px - 16px);
    left: 16px;
    right: 16px;
    padding: 16px;
    border-radius: 8px;
    position: fixed;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    z-index: 3;
}

.mob-header-dropdown-box {
    width: calc(100vw - 16px - 16px);
    top: 72px;
    left: 16px;
    right: 16px;
    padding: 16px;
    border-radius: 0px 0px 8px 8px;
    position: fixed;
    border-top: 1px solid #F1F2F3;
    z-index: 2;
}

.mob-header-dropdown-row {
    padding: 12px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.mob-header-dropdown-menu-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}
.mob-footer-wrapper {
    width: 100vw;
    background-color: #F1F2F3;
    padding: 16px 0;
    bottom: 0;
    position: fixed;
}

.mob-footer-box {
    width: calc(100vw - 16px - 16px);
    padding: 16px;
    margin: 0 16px;
    border-radius: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.mob-footer-connect-wallet-button {
    width: 139px;
    height: 40px;
}

.mob-footer-blockchain-button {
    padding: 10px;
}

.mob-footer-address-button {
    width: 165px;
    height: 40px;
}

.mob-footer-amount-section {
    height: 24px;
    width: 77px;
    border-radius: 999px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.mob-footer-network-icon {
    width: 20px;
    height: 20px;
}
.sidebar-wrapper {
    width: 240px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
}

.sidebar {
    width: 80px;
    height: 100%;
}

.sidebar-content {
    padding: 16px 8px;
}

.sidebar-logo-section {
    height: 184px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    /* align-items: center; */
    -webkit-justify-content: center;
            justify-content: center;
}

.sidebar-logo {
    height: 84px;
    width: auto;
}

.sidebar-logo:hover {
    cursor: pointer;
}

.sidebar-menu {
    padding: 16px 0;
}

.sidebar-item {
    display: -webkit-flex;
    display: flex;
}

.sidebar-item-icon-section {
    width: 48px;
    height: 48px;
    margin: 0 20px;
}

.sidebar-item-icon-section:hover {
    cursor: pointer;
}

.sidebar-hover-section {
    height: 100%;
    padding: calc(204px + 8px) 0 0 0;
}

.sidebar-hover-menu-row {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
    height: 40px;
    margin: 0 0 8px 0;
}

.sidebar-hover-menu-selector-section {
    margin-left: -16px;
}

.sidebar-hover-menu-item-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
    margin-left: 8px;
}

.sidebar-hover-menu-item {
    height: 40px;
    padding: 12px 16px;
    border-radius: 8px;
    margin-left: -1px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.sidebar-hover-menu-item-bullet {
    background: linear-gradient(90deg, #FDECFA 0%, #FFEBF1 100%);
    border-radius: 999px;
    padding: 4px 8px;
    margin-left: 8px;
}

.sidebar-menu-selector-section {
    margin-left: -8px;
    margin-top: -8px;
}
.portfolio-news-layout {
    margin: 16px 0 72px 0;
}

.portfolio-news-cards-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.portfolio-card {

}

.portfolio-card-bg-img.certik-card {

}

.portfolio-card-bg-img {
    width: 288px;
    height: 150px;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
}

.portfolio-card-bg-img:hover {
    cursor: pointer;
    box-shadow: 0px 8px 36px 0px #5C5C702B;
}

.portfolio-card-text-section {
    position: absolute;
    margin-top: -148px;
    margin-left: 16px;
}

.portfolio-card-text-section.certik-card {
    margin-top: -148px;
    margin-left: 142px;
}

.portfolio-card-update-text-section {
    position: absolute;
    margin-top: -148px;
    margin-left: 132px;
    width: 139px;
}

.portfolio-card-update-text-section.crv-card {
    margin-left: 148px;
}

.portfolio-card-button {
    width: 51px;
    height: 24px;
    text-align: center;
    margin-bottom: 8px;
}

.portfolio-elipses-section {
    width: 200px;
    margin: 32px auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

@media(max-width: 1299px) {
    .portfolio-elipses-section {
        margin-top: 48px;
    }
}

.portfolio-elipses-w-section {
    width: 50px;
    margin: 32px auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.portfolio-selected-elipse {
    width: 12px;
    height: 12px;
    background-color: #65657B;
    border-radius: 999px;
}

.portfolio-elipse {
    width: 12px;
    height: 12px;
    background-color: #C7C7D1;
    border-radius: 999px;
}

@media(max-width: 1288px) {
    .portfolio-card-bg-img {
       width: 100%;
       height: auto;
    }
}

.portfolio-elipse:hover, .portfolio-selected-elipse:hover {
    cursor: pointer;
}

.portfolio-card-assets-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 30px;
}

.portfolio-card-asset-icon {
    width: 24px;
    height: 24px;
    border-radius: 999px;
}

.portfolio-card-asset-icon.second-icon {
    margin-left: -12px;
}

.portfolio-gen-info-cards-section {
    margin: 24px 0;
}

@media(min-width: 1300px) {
    .portfolio-gen-info-cards-section {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
}

.portfolio-gen-info-card {
    width: 389.33px;
    height: 140px;
    padding: 24px;
    border-radius: 8px;
}

@media(max-width: 1299px) {
    .portfolio-gen-info-card {
        width: 100%;
        margin-bottom: 16px;
    }
}

.portfolio-gen-info-card-value-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    margin-top: 16px;
}

.portfolio-gen-info-card-primary-value {
    margin-right: 8px;
}

.portfolio-gen-info-card-links-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    margin-top: 28px;
}

.portfolio-gen-info-card-link {
    cursor: pointer;
    margin-right: 16px;
}

.portfolio-position-card {
    width: 389.33px;
    border-radius: 8px;
    padding: 24px;
    margin-right: 16px;
}

@media(max-width: 1299px) {
    .portfolio-position-card {
        width: 100%;
        margin: 16px 0;
    }
}

s-section-header {
    margin: 24px 0 16px;
}

.portfolio-position-cards-section {
    margin: 0 0 24px 0;
}

@media(min-width: 1300px) {
    .portfolio-position-cards-section {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: left;
                justify-content: left;
    }
}

.portfolio-position-card-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 18px;
}

.portfolio-position-new-line {
    
}

.portfolio-position-card-header-left-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.portfolio-position-card-header-coll-icon {
    width: 48px;
    height: 48px;
    margin-right: 12px;
}

.portfolio-position-card-header-asset-icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
}

.portfolio-bullet-tag {
    background: linear-gradient(90deg, #FDECFA 0%, #FFEBF1 100%);
    height: 24px;
    border-radius: 999px;
    padding: 4px 8px;
}

.portfolio-link-button {
    width: 100%;
    height: 40px;
    padding: 8px 0;
    margin-top: 18px;
}

.portfolio-open-pos-card-buttons-section {
    padding: 24px 0 0 0;
    border-top: 1px solid #F1F2F3;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.portfolio-position-cards-section-header {
    margin: 48px 0 16px 0;
}

.portfolio-card-pool-asset-icons-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 4px;
}

.portfolio-card-pool-asset-icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}
.buy-radar-overlay-close-wrapper {
    position: fixed;
    top: 72px;
    left: 80px;
    width: 100vw;
    height: 100vh;
}

.buy-radar-overlay-content {
    width: 320px;
    margin-left: calc((100vw - 320px - 80px)/2);
    margin-top: 186px;
    text-align: center;
    position: absolute;
    z-index: 5000;
}

@media(max-width: 1299px) {
    .buy-radar-overlay-content {
        margin-left: calc((100vw - 320px)/2);
    }
}

@media(max-width: 340px) {
    .buy-radar-overlay-content {
        width: 100%;
    }
}

.buy-radar-link-box {
    width: 100%;
    height: 48px;
    margin: 0 0 16px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 8px;
    padding: 14px 24px;
}

.buy-radar-link-box:hover {
    cursor: pointer;
    box-shadow: 0px 8px 36px 0px #5C5C702B;
}

.buy-radar-link-icon, .buy-asset-link-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 999px;
}

.buy-asset-link-icon {
    margin-left: -18px;
}

.buy-radar-link-box-left-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}
.stage.overlay {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 1200px;
    margin: auto;
}

@media(max-width: 1299px) {
    .stage.overlay {
        width: 100%;
    }
}

.dot-flashing.overlay {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 999px;
    background-color: #8F8FA3;
    color: #8F8FA3;
    -webkit-animation: dotFlashing 1s infinite linear alternate;
            animation: dotFlashing 1s infinite linear alternate;
    -webkit-animation-delay: .5s;
            animation-delay: .5s;
}

.dot-flashing.overlay::before, .dot-flashing.overlay::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
.dot-flashing.overlay::before {
    left: -32px;
    width: 16px;
    height: 16px;
    border-radius: 999px;
    background-color: #8F8FA3;
    color: #8F8FA3;
    -webkit-animation: dotFlashing 1s infinite alternate;
            animation: dotFlashing 1s infinite alternate;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}
  
.dot-flashing.overlay::after {
    left: 32px;
    width: 16px;
    height: 16px;
    border-radius: 999px;
    background-color: #8F8FA3;
    color: #8F8FA3;
    -webkit-animation: dotFlashing 1s infinite alternate;
            animation: dotFlashing 1s infinite alternate;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

@-webkit-keyframes dotFlashing {
    0% {
      background-color: #8F8FA3;
    }
    50%,
    100% {
      background-color: #E3E3E8;
    }
}

@keyframes dotFlashing {
    0% {
      background-color: #8F8FA3;
    }
    50%,
    100% {
      background-color: #E3E3E8;
    }
}

.loader-overlay-content {
    margin-left: -88px;
}

@media(max-width: 1299px) {
    .loader-overlay-content {
        margin-left: 0;
    }
}

.loader-overlay-info-section {
    text-align: center;
    width: 1200px;
    margin: 186px auto 0 auto;
}

@media(max-width: 1299px) {
    .loader-overlay-info-section {
        text-align: center;
        width: 100%;
        margin: 30% auto 0 auto;
    }
}

.loader-overlay-header {
    margin: 16px 0;
}

.loader-overlay-subheader {
    margin: 0 0 32px 0;
}
.wnc-text-section {
    width: 1200px;
    margin-left: calc((100vw - 80px - 1200px)/2);
    text-align: center;
    margin-top: 186px;
}

@media(max-width: 1299px) {
    .wnc-text-section {
        width: 80%;
        margin: 30% auto;
    }
}

.wnc-connect-button {
    width: 320px;
    margin: 32px auto 0 auto;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

@media(max-width: 1299px) {
    .wnc-connect-button {
        width: 200px;
    }
}

.sn-switch-button {
    width: 320px;
    margin: 0 auto 16px auto;
    height: 48px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.sn-switch-button:hover {
    box-shadow: 0px 4px 16px 0px #5C5C701F;
}

.sn-secondary-text {
    margin: 16px 0 32px 0;
}

.sn-blockchain-icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
}
.stake-top-info-layout {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 16px 0;
}

.stake-top-info-separator {
    width: 1px;
    height: 16px;
    background-color: #C7C7D1;
    margin: 0 24px;
}

@media(max-width: 1299px) {
    .stake-top-info-separator {
        height: 40px;
    }
}

@media(min-width: 1300px) {
    .stake-top-info-section {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    
}

.stake-top-info-value {
    margin-left: 8px;
}

@media(max-width: 1299px) {
    .stake-top-info-value {
        margin-left: 0;
        margin-top: 8px;
    }
}

.stake-page-reward-claim-button-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.borrow-table-b-cell {
    width: 320px;
}

.borrow-table-s-cell {
   width: 128px;
}

.borrow-table-pool-name {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.borrow-table-pool-assets {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.borrow-table-b-icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
}

.borrow-table-s-icon {
    width: 20px;
    height: 20px;
    margin-right: 2px;
}
.tooltip-layout {
    width: 224px;
    position: absolute;
}

.tooltip-box {
    padding: 12px 16px;
    border-radius: 8px;
}

.tooltip-triangle-icon-section {
    width: 100%;
    text-align: center;
    margin-bottom: -6px;
}

.tooltip-triangle-icon {
    width: 16px;
    height: 8px;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
}
.progress {
    height: 8px !important;
    border-radius: 8px;
    background-color: #F1F2F3;;
}

.progress-bar {
    box-shadow:none;
    -webkit-box-shadow:none;
    background: linear-gradient(90deg, #FFAA99 0%, #FFE699 51.56%, #AEEAD6 100%);
}

.borrow-card-borrow-button {
    cursor: pointer;

}

.borrow-card-withdraw-button {
    cursor: pointer;
}

.borrow-card-repay-button {
    cursor: pointer;
}

.borrow-repay-method-box {
    width: 100%;
    padding: 16px;
    background-color: #F1F2F3;
    border-radius: 8px;
}

.borrow-repay-popup-content {
    margin-top: 26px;
}

.borrow-repay-method-box-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.borrow-repay-selected-method-circle {
    width: 20px;
    height: 20px;
    border-radius: 999px;
    border: 6px solid #E926C3;
    cursor: pointer;
    margin-right: 8px; 
}

.borrow-repay-not-selected-method-circle {
    width: 20px;
    height: 20px;
    border-radius: 999px;
    border: 1px solid #2E3338;
    cursor: pointer;
    margin-right: 8px; 
}

.borrow-header-sect {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.borrow-repay-method-box-header-left-sect {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
}

.no-tooltip {
    display: none;
}

.max-col-tooltip {
    margin-left: 314px;
    margin-top: -28px;
}

.liq-fee-tooltip {
    margin-left: 514px;
    margin-top: -28px;
}

.stab-fee-tooltip {
    margin-left: 714px;
    margin-top: -28px;
}

.liq-price-tooltip {
    margin-left: -42px;
    margin-top: -440px;
}

.asset-price-tooltip {
    margin-left: 558px;
    margin-top: -440px;
}

.pos-health-tooltip {
    margin-top: -380px;
    margin-left: -40px;
}

.small-card-tooltip {
    position: absolute;
    margin-left: -48px;
    margin-top: -8px;
}
.multiply-table-b-cell {
    width: 320px;
}

.multiply-table-s-cell {
   width: 128px;
}

.multiply-table-pool-name {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.multiply-table-pool-assets {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.multiply-table-b-icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
}

.multiply-table-s-icon {
    width: 20px;
    height: 20px;
    margin-right: 2px;
}

.no-tooltip {
    display: none;
}

.max-col-tooltip {
    margin-left: 314px;
    margin-top: -28px;
}

.liq-fee-tooltip {
    margin-left: 514px;
    margin-top: -28px;
}

.stab-fee-tooltip {
    margin-left: 714px;
    margin-top: -28px;
}

.liq-price-tooltip {
    margin-left: -42px;
    margin-top: -440px;
}

.asset-price-tooltip {
    margin-left: 558px;
    margin-top: -440px;
}

.pos-health-tooltip {
    margin-top: -380px;
    margin-left: -40px;
}

.small-card-tooltip {
    position: absolute;
    margin-left: -48px;
    margin-top: -8px;
}
.bonds-table-b-cell {
    width: 408px;
}

.bonds-table-s-cell {
    width: 128px;
}

.bonds-table-flex-cell {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.bonds-table-icons-section {
    width: 48px;
}

.bonds-table-icon1, .bonds-table-icon2 {
    border-radius: 999px;
    width: 32px;
    height: 32px;
}

.bonds-table-icon2 {
    margin-left: -16px;
}

.bonds-table-text-section {
    margin-left: 12px;
} 
.pool-top-info-layout {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 16px 0;
}

.pool-top-info-separator {
    width: 1px;
    height: 16px;
    background-color: #C7C7D1;
    margin: 0 24px;
}

@media(max-width: 1299px) {
    .pool-top-info-separator {
        margin: 0 8px;
        height: 32px;
    }
}

.pool-top-info-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    height: 24px;
}

.pool-top-info-value {
    margin-left: 8px;
}

.pool-wrong-network-section {
    text-align: center;
    margin: 128px 0;
}

.pool-wrong-network-link:hover {
    cursor: pointer;
}

.pool-page-reward-card-claim-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.pool-page-reward-card-button {
    margin-bottom: 8px;
}

.pool-page-reward-card-button:hover {
    cursor: pointer;
}

.pool-page-reward-bullet {
    padding: 2px 0;
    border-radius: 999px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 112px;
    margin-top: 12px;
}

.pool-page-stake-card-header {
    text-align: center;
    margin: 26px 0 0 0;
}

.pool-page-stake-card-amount {
    text-align: center;
    margin: 8px 0 24px 0;
}

.pool-page-stake-button {
    width: 100%;
    height: 40px;
}

.pool-page-stake-card-claim-info {
    width: 231px;
    text-align: center;
    margin: 24px auto 0 auto;
}

.pool-page-stake-card-claim-button:hover {
    cursor: pointer;
}
.bridge-step-disabled {
    width: 584px;
    margin: auto;
    height: 72px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 24px;
    border-radius: 8px;
    margin-top: 16px;
    -webkit-animation-name: bridgeCardAnimation;
            animation-name: bridgeCardAnimation;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
}

.bridge-step-no {
    width: 24px;
    height: 24px;
    border-radius: 999px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.bridge-step-card {
    width: 584px;
    margin: auto;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    margin-top: 16px;
    -webkit-animation-name: bridgeCardAnimation;
            animation-name: bridgeCardAnimation;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
} 

@media(max-width: 1299px) {
    .bridge-step-card, .bridge-input-menu {
        width: 100%;
    }
}

@-webkit-keyframes bridgeCardAnimation {
    0%   {margin-top:0px;}
    100% {margin-top:16px;}
}

@keyframes bridgeCardAnimation {
    0%   {margin-top:0px;}
    100% {margin-top:16px;}
}

.bridge-step-card-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 24px;
}

.bridge-dropdown-selector-box {
    width: 100%;
    height: 48px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    border: 1px solid #E3E3E8;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
    margin-top: 8px;
}

@media(max-width: 1299px) {
    .bridge-dropdown-selector-box {
        height: 76px;
    }
}

.bridge-dropdown-selector-box.gradient-border {
    border: 1px solid #E926C3;
}

.bridge-dropdown-selector-box:hover {
    cursor: pointer;
    border: 1px solid #E926C3;
}

.bridge-dropdown-selector-box-asset-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.bridge-dropdown-asset-icon {
    border-radius: 999px;
    margin-right: 8px;
    width: 20px;
    height: 20px;
}

.bridge-dropdown {
    width: 536px;
    padding: 8px;
    position: absolute;
    display: block;
    z-index: 1;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 2px;
}

@media(max-width: 1299px) {
    .bridge-dropdown {
        width: 80%;
    }
}

.bridge-dropdown.half-dd {
    width: 260px;
}

.bridge-dropdown-row {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 16px;
}

.bridge-dropdown-row:hover {
    background-color: #F1F2F3;
    border-radius: 8px;
    cursor: pointer;
}

.bridge-blockchains-frame {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin: 24px 0;
}

.bridge-blockchain-section {
    width: 260px;
}

.bridge-close-dropdowns-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
}

/* .bridge-balance-button:hover {
    cursor: pointer;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */

.bridge-amount-info {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 8px;
}

.bridge-amount-balance-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
            justify-content: right;
}

.bridge-amount-balance-section:hover {
    cursor: pointer;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bridge-balance-button {
    margin-right: 4px;
}

.bridge-address-toggle-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 24px;
    margin-bottom: 16px;
}

.bridge-address-toggle-section:hover {
    cursor: pointer;
}

.bridge-address-toggle-info {
    margin-left: 8px;
}

.bridge-address-toggle-off-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.bridge-same-address-button:hover {
    cursor: pointer;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bridge-step-done {
    width: 584px;
    margin: auto;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    margin-top: 16px;
    -webkit-animation-name: bridgeCardAnimation;
            animation-name: bridgeCardAnimation;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
}

.bridge-step-done-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.bridge-step-done-info-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 24px;
}

.bridge-transaction-counter {
    height: 92px;
    width: 92px;
    margin: auto;
    border-radius: 999px;
    padding: 12px;
    text-align: center;
    padding: 26px 0;
}

.bridge-step-info-text {
    width: 414px;
    margin: auto;
    text-align: center;
    padding: 24px 0;
}

@media(max-width: 1299px) {
    .bridge-step-disabled, .bridge-step-card, .bridge-step-done {
        width: 100%;
    }

    .bridge-dropdown.half-dd {
        width: 40%;
    }
    
    .bridge-step-info-text {
       width: 100%; 
    }
}

.bridge-trans-id-box {
    width: 330px;
    margin: auto;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #F1F2F3;
    padding: 8px 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

@media(max-width: 400px) {
    .bridge-trans-id-box {
        width: 100%;
    }
}

.bridge-copy-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.bridge-step3-button {
    width: 355px;
    margin: auto;
    height: 48px;
}

@media(max-width: 400px) {
    .bridge-step3-button {
        width: 100%;
    }
}

.bridge-step3-button.gradient-border {
    border: 1px solid #E926C3;
    margin: 16px auto 8px auto;
}

@media(min-width: 401px) {
    .bridge-step3-info {
        width: 355px;
        margin: auto;
        text-align: center;
    }
}

.bridge-substep-card {
    width: 100%;
    border-radius: 8px;
    padding: 16px;
}

.bridge-substep-card2 {
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    margin: 32px 0;
    border: 1px solid #F1F2F3;
    background-color: #FFF;
}

.bridge-substep-card2:hover {
    cursor: pointer;
    background-color: #F1F2F3 !important;
}

.bridge-substep-card2:hover .bridge-substep-not-selected-radio {
    background: linear-gradient(90deg, #FF4D86 0%, #E926C3 100%);
    border: none;
}

.bridge-substep-card-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.bridge-substep-selected-radio {
    width: 16px;
    height: 16px;
    border-radius: 999px;
    /* background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%); */
    margin-right: 13.33px;
    border: 4px solid #FF4D86;
}

.bridge-substep-not-selected-radio {
    width: 16px;
    height: 16px;
    border-radius: 999px;
    background: white;
    border: 1px solid #2E3338;
    margin-right: 13.33px;
}

.bridge-tx-dropdown-selector-box {
    width: 100%;
    height: 48px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    background-color: white;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
    margin-top: 8px;
}

.bridge-tx-dropdown-selector-box:hover {
    cursor: pointer;
}

.bridge-dropdown.tx-full-dd {
    width: 504px;
}

.bridge-tx-box-info {
    margin: 16px 0 8px 0;
}

.bridge-tx-input-box {
    background-color: white;
}

.bridge-done-card-info {
    margin: 16px 0 0 0;
}

@media(max-width: 1299px) {
    .bridge-dropdown.tx-full-dd {
        width: 70%;
    }
}

.bridge-scan-button {
    width: 355px;
    margin: auto;
    height: 48px;
}

@media(max-width: 400px) {
    .bridge-scan-button {
        width: 100%;
    }
}

.bridge-transaction-box {
    width: 100%;
    height: 72px;
    border: 1px solid #F1F2F3;
    border-radius: 8px;
    padding: 20px 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.bridge-transaction-box-left-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.bridge-transaction-box-asset-icon {
    width: 32px;
    height: 32px;
    border-radius: 999px;
    margin-right: 12px;
}

.bridge-transaction-box-arrow-right-icon {
    width: 20px;
    height: 20px;
    border-radius: 999px;
}

.bridge-transaction-box-blockchain-icon {
    width: 24px;
    height: 24px;
    margin: 0 12px;
}

.bridge-transaction-box-button {
    border: 1px solid #E926C3;
    border-radius: 8px;
    padding: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}

.bridge-transaction-box-button:hover {
    cursor: pointer;
    border: 1px solid #FF4D86;
}
.bridge-input-menu {
    width: 584px;
    height: 48px;
    margin: auto;
    padding: 4px;
    border-radius: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.bridge-input-menu-item {
    width: 288px;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 8px;
}

@media(max-width: 1299px) {
    .bridge-input-menu {
        width: 100%;
    }

    .bridge-input-menu-item  {
        width: 49%;
    }
}

.bridge-input-menu-item-pink-badge {
    width: 24px;
    height: 24px;
    border-radius: 999px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color:#FDECFA;
    margin-left: 8px;
}
.stab-single-card-left-side {
    width: 292px;
    margin-right: 16px;
}

@media(max-width: 1299px) {
    .stab-single-card-left-side {
        width: 100%;
        margin-right: 0;
    }
}

.stab-card-info-icon {
    cursor: pointer;
}

.fee-div {
    margin-top: 8px;
    text-align: right;
}

.stabilizer-stablecoins-dropdown {
    position: absolute;
    width: 220px;
    padding: 8px;
    z-index: 1;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
    margin-top: 8px;
}

@media(max-width: 1299px) {
    .stabilizer-stablecoins-dropdown {
        width: auto;
    }
}

.stab-results-box {
    width: 100%;
    height: 64px;
    border: 1px solid #E3E3E8;
    border-radius: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.stab-results-box-left-section {
    width: 425px;
    height: 100%;
    padding: 16px;
    border-radius: 8px 0px 0px 8px;
}

.stab-results-box-right-section {
    width: 111px;
    height: 100%;
    padding: 16px;
    border-radius: 0px 8px 8px 0px;
}

.stab-results-box-right-section-box {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 6px 8px;
}

.stab-results-box-right-section-icon {
    width: 20px;
    height: 20px;
}

.stab-switch-icon-section {
    text-align: center;
    margin: 32px auto 0 auto;
    width: 32px;
    height: 32px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.stab-switch-icon-section:hover {
    cursor: pointer;
    border-radius: 999px;
    background-color: #f7f7f7;
}

.stab-results-box-left-section2 {
    width: 399px;
    height: 100%;
    padding: 16px;
    border-radius: 8px 0px 0px 8px;
}

.stab-results-box-right-section2 {
    width: 137px;
    height: 100%;
    padding: 16px;
    border-radius: 0px 8px 8px 0px;
}

.stab-asset-dropdown2 {
    
}

.stabilizer-tutorial-link-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 8px;
}
.farm-other-pools-header {
    margin: 48px 0;
}

.farm-table-s-cell {
    width: 200px;
}

.farm-table-b-cell {
    width: 400px;
}

.farm-table-cell {
    width: 300px;
}

.farm-table-pool-name {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

.farm-table-b-icon1 {
    width: 32px;
    height: 32px;
    border-radius: 999px;
}

.farm-table-b-icon2 {
    width: 32px;
    height: 32px;
    border-radius: 999px;
    margin-left: -16px;
}

.farm-table-pool-name-text {
    margin-left: 12px;
}

.farm-right {
    text-align: right;
}

.farm-table-blockchain-icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.farm-table-asset-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.farm-table-token-earned-cell {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
}

@media(max-width: 1299px) {
    .farm-table-asset-icon {
        margin-left: 4px;
        margin-right: 0;
    }

    .farm-table-pool-name-text {
        margin-left: 4px;
    }

    .farm-table-blockchain-icon {
        margin-right: 4px;
    }
}
.farm-withdraw-label {
    cursor: pointer;
}

.farm-claim-label {
    cursor: pointer;
}

.farm-pool-card-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.redux-toastr .toastr.rrt-error {
    background-color: #FFEEEB ;
    padding: 16px 16px 32px 16px;
    border-radius: 8px !important;
    opacity: 1 !important;
    box-shadow: 0px 4px 16px 0px #5C5C701F !important;
}

.redux-toastr .toastr.rrt-success {
    background-color: #EBFAF5 ;
    padding: 16px 16px 32px 16px;
    border-radius: 8px !important;
    opacity: 1 !important;
    box-shadow: 0px 4px 16px 0px #5C5C701F !important;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
    fill: #2E3338;
    width: 24px !important;
    margin-top: 0px;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder {
    width: auto !important;
    height: auto !important;
    position: static;
    position: initial;
    top: 0%;
    margin-top: 0px;
    left: 0px;
    line-height: 24px;
}

.redux-toastr .toastr .rrt-left-container {
    position: static;
    position: initial;
    width: 24px ;
}

.redux-toastr .toastr .rrt-middle-container {
    width: auto;
    margin-left: 16px;
    position: static;
    position: initial;
    float: left;
    font-family: 'DM Sans', sans-serif ;
    font-size: 1em;
    text-align: left;
    padding: 0px;
    color: #2E3338;
}

.redux-toastr .toastr .close-toastr {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: transparent;
    font-size: 16px;
    border: none;
    outline: none;
    opacity: 1;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif ;
    color: #2E3338;
    font-weight: 900;
}

.redux-toastr .toastr.rrt-error .rrt-progressbar {
    background-color: #FF5330;
}

.redux-toastr .toastr.rrt-success .rrt-progressbar {
    background-color: #34C796;
}

.redux-toastr .toastr .rrt-middle-container .rrt-title {
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
}

.redux-toastr .toastr .rrt-middle-container .rrt-text {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
}
