.sn-switch-button {
    width: 320px;
    margin: 0 auto 16px auto;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sn-switch-button:hover {
    box-shadow: 0px 4px 16px 0px #5C5C701F;
}

.sn-secondary-text {
    margin: 16px 0 32px 0;
}

.sn-blockchain-icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
}