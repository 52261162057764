.redux-toastr .toastr.rrt-error {
    background-color: #FFEEEB ;
    padding: 16px 16px 32px 16px;
    border-radius: 8px !important;
    opacity: 1 !important;
    box-shadow: 0px 4px 16px 0px #5C5C701F !important;
}

.redux-toastr .toastr.rrt-success {
    background-color: #EBFAF5 ;
    padding: 16px 16px 32px 16px;
    border-radius: 8px !important;
    opacity: 1 !important;
    box-shadow: 0px 4px 16px 0px #5C5C701F !important;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
    fill: #2E3338;
    width: 24px !important;
    margin-top: 0px;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder {
    width: auto !important;
    height: auto !important;
    position: initial;
    top: 0%;
    margin-top: 0px;
    left: 0px;
    line-height: 24px;
}

.redux-toastr .toastr .rrt-left-container {
    position: initial;
    width: 24px ;
}

.redux-toastr .toastr .rrt-middle-container {
    width: auto;
    margin-left: 16px;
    position: initial;
    float: left;
    font-family: 'DM Sans', sans-serif ;
    font-size: 1em;
    text-align: left;
    padding: 0px;
    color: #2E3338;
}

.redux-toastr .toastr .close-toastr {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: transparent;
    font-size: 16px;
    border: none;
    outline: none;
    opacity: 1;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif ;
    color: #2E3338;
    font-weight: 900;
}

.redux-toastr .toastr.rrt-error .rrt-progressbar {
    background-color: #FF5330;
}

.redux-toastr .toastr.rrt-success .rrt-progressbar {
    background-color: #34C796;
}

.redux-toastr .toastr .rrt-middle-container .rrt-title {
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
}

.redux-toastr .toastr .rrt-middle-container .rrt-text {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
}