.bridge-step-disabled {
    width: 584px;
    margin: auto;
    height: 72px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-radius: 8px;
    margin-top: 16px;
    animation-name: bridgeCardAnimation;
    animation-duration: 1s;
}

.bridge-step-no {
    width: 24px;
    height: 24px;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bridge-step-card {
    width: 584px;
    margin: auto;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    margin-top: 16px;
    animation-name: bridgeCardAnimation;
    animation-duration: 1s;
} 

@media(max-width: 1299px) {
    .bridge-step-card, .bridge-input-menu {
        width: 100%;
    }
}

@keyframes bridgeCardAnimation {
    0%   {margin-top:0px;}
    100% {margin-top:16px;}
}

.bridge-step-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.bridge-dropdown-selector-box {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #E3E3E8;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
    margin-top: 8px;
}

@media(max-width: 1299px) {
    .bridge-dropdown-selector-box {
        height: 76px;
    }
}

.bridge-dropdown-selector-box.gradient-border {
    border: 1px solid #E926C3;
}

.bridge-dropdown-selector-box:hover {
    cursor: pointer;
    border: 1px solid #E926C3;
}

.bridge-dropdown-selector-box-asset-section {
    display: flex;
    justify-content: left;
    align-items: center;
}

.bridge-dropdown-asset-icon {
    border-radius: 999px;
    margin-right: 8px;
    width: 20px;
    height: 20px;
}

.bridge-dropdown {
    width: 536px;
    padding: 8px;
    position: absolute;
    display: block;
    z-index: 1;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 2px;
}

@media(max-width: 1299px) {
    .bridge-dropdown {
        width: 80%;
    }
}

.bridge-dropdown.half-dd {
    width: 260px;
}

.bridge-dropdown-row {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px 16px;
}

.bridge-dropdown-row:hover {
    background-color: #F1F2F3;
    border-radius: 8px;
    cursor: pointer;
}

.bridge-blockchains-frame {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
}

.bridge-blockchain-section {
    width: 260px;
}

.bridge-close-dropdowns-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
}

/* .bridge-balance-button:hover {
    cursor: pointer;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */

.bridge-amount-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.bridge-amount-balance-section {
    display: flex;
    justify-content: right;
}

.bridge-amount-balance-section:hover {
    cursor: pointer;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bridge-balance-button {
    margin-right: 4px;
}

.bridge-address-toggle-section {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 16px;
}

.bridge-address-toggle-section:hover {
    cursor: pointer;
}

.bridge-address-toggle-info {
    margin-left: 8px;
}

.bridge-address-toggle-off-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bridge-same-address-button:hover {
    cursor: pointer;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bridge-step-done {
    width: 584px;
    margin: auto;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    margin-top: 16px;
    animation-name: bridgeCardAnimation;
    animation-duration: 1s;
}

.bridge-step-done-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bridge-step-done-info-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.bridge-transaction-counter {
    height: 92px;
    width: 92px;
    margin: auto;
    border-radius: 999px;
    padding: 12px;
    text-align: center;
    padding: 26px 0;
}

.bridge-step-info-text {
    width: 414px;
    margin: auto;
    text-align: center;
    padding: 24px 0;
}

@media(max-width: 1299px) {
    .bridge-step-disabled, .bridge-step-card, .bridge-step-done {
        width: 100%;
    }

    .bridge-dropdown.half-dd {
        width: 40%;
    }
    
    .bridge-step-info-text {
       width: 100%; 
    }
}

.bridge-trans-id-box {
    width: 330px;
    margin: auto;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #F1F2F3;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media(max-width: 400px) {
    .bridge-trans-id-box {
        width: 100%;
    }
}

.bridge-copy-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.bridge-step3-button {
    width: 355px;
    margin: auto;
    height: 48px;
}

@media(max-width: 400px) {
    .bridge-step3-button {
        width: 100%;
    }
}

.bridge-step3-button.gradient-border {
    border: 1px solid #E926C3;
    margin: 16px auto 8px auto;
}

@media(min-width: 401px) {
    .bridge-step3-info {
        width: 355px;
        margin: auto;
        text-align: center;
    }
}
