.blockchain-dropdown-wrapper {
    width: 100%;
    height: 100%;
    margin-left: 80px;
    position: fixed;
    z-index: 2;
}

@media(max-width: 1299px) {
    .blockchain-dropdown-wrapper {
        margin-left: 0;
    }
}

.blockchain-dropdown-layout {
   display: flex;
   justify-content: right;
   margin-right: calc(80px + (100% - 80px - 1200px)/2 + 165px + 16px);
}

@media(max-width: 1299px) {
    .blockchain-dropdown-layout {
        margin-right: 0;
        bottom: 100px;
        left: calc(100vw - 16px - 272px);
        position: fixed;
    }
}

.blockchain-dropdown {
    width: 272px;
    padding: 8px;
    border-radius: 8px;
}

@keyframes blockchainDropdownAnimation {
    0%   {margin-top:-16px;}
    50%  {margin-top:-8px;}
    100% {margin-top:-8px;}
}

.blockchain-dropdown-on-scroll {
    width: 272px;
    padding: 8px;
    border-radius: 8px;
    margin-top: 64px;
    margin-right: 16px;
}

@keyframes blockchainDropdownAnimationOnScroll {

}

.blockchain-dropdown-row {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px 16px;
}

.blockchain-dropdown-row:hover {
    cursor: pointer;
}

.blockchain-dropdown-row-icon {
    width: 20px;
    height: 20px;
}

.blockchain-dropdown-row-text {
    margin-left: 12px;
}