.farm-withdraw-label {
    cursor: pointer;
}

.farm-claim-label {
    cursor: pointer;
}

.farm-pool-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}