.account-overlay-wrapper {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: #f1f2f370;
}

.account-overlay-close-wrapper {
    width: 100%;
    height: 100%;
    right: 512px;
    position: fixed;
    cursor: pointer;
}

.account-overlay-layout {
    display: flex;
    justify-content: right;
    align-items: right;
}

.account-overlay-sidebar {
    width: 512px;
    height: 100vh;
    background-color: #FFF;
    padding: 32px 24px;
    animation-name: accountOverlaySidebarAnimation;
    animation-duration: 1s;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
}

@media(max-width: 1299px) {
    .account-overlay-sidebar {
        width: 95%;
        margin: 100px auto 0 auto;
        height: 100%;
        border-radius: 8px;
        animation: none;
    }
}

@keyframes accountOverlaySidebarAnimation {
    0%   {margin-right: -400px};
    50%  {margin-right: 20px;}
    100% {margin-right: 0px;}
}

.account-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.account-sidebar-section {
    width: 100%;
    border: 1px solid #F1F2F3;
    border-radius: 8px;
    margin: 24px 0;
    padding: 24px;
}

.account-sidebar-subsection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid #F1F2F3;
}

.account-sidebar-address-box {
    background-color: #F1F2F3;
    padding: 8px 12px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-sidebar-address-box-metamask-icon {
    margin-right: 8px;
}

.account-sidebar-address-box-copy-icon {
    width: 20px;
    height: 20px;
    margin: 0 8px;
}

.account-sidebar-address-box-link-icon {
    width: 20px;
    height: 20px;
}

.account-sidebar-address-box-copy-icon:hover,
.account-sidebar-address-box-link-icon:hover {
    cursor: pointer;
    opacity: 0.8;
}

.account-sidebar-subsection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.account-sidebar-subsection-left {
    display: flex;
    justify-content: left;
    align-items: center;
}

.account-sidebar-subsection-right:hover {
    cursor: pointer;
    opacity: 0.8;
}

.account-sidebar-subsection-header {
    margin: 16px 0;
}

.account-sidebar-subsection-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;
}

.account-sidebar-subsection-row-left {
    display: flex;
    justify-content: left;
    align-items: center;
}

.account-sidebar-asset-icon {
    width: 32px;
    height: 32px;
    margin: 0 8px 0 0;
    border-radius: 999px;
}

.account-overlay-balance {
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.account-overlay-add-button {
    cursor: pointer;
}

.account-sidebar-slip-sett {
    display: flex;
    justify-content: space-between;
    align-items: center;
}