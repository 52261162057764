.tooltip-layout {
    width: 224px;
    position: absolute;
}

.tooltip-box {
    padding: 12px 16px;
    border-radius: 8px;
}

.tooltip-triangle-icon-section {
    width: 100%;
    text-align: center;
    margin-bottom: -6px;
}

.tooltip-triangle-icon {
    width: 16px;
    height: 8px;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
}