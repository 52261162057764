.buy-radar-overlay-close-wrapper {
    position: fixed;
    top: 72px;
    left: 80px;
    width: 100vw;
    height: 100vh;
}

.buy-radar-overlay-content {
    width: 320px;
    margin-left: calc((100vw - 320px - 80px)/2);
    margin-top: 186px;
    text-align: center;
    position: absolute;
    z-index: 5000;
}

@media(max-width: 1299px) {
    .buy-radar-overlay-content {
        margin-left: calc((100vw - 320px)/2);
    }
}

@media(max-width: 340px) {
    .buy-radar-overlay-content {
        width: 100%;
    }
}

.buy-radar-link-box {
    width: 100%;
    height: 48px;
    margin: 0 0 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 14px 24px;
}

.buy-radar-link-box:hover {
    cursor: pointer;
    box-shadow: 0px 8px 36px 0px #5C5C702B;
}

.buy-radar-link-icon, .buy-asset-link-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 999px;
}

.buy-asset-link-icon {
    margin-left: -18px;
}

.buy-radar-link-box-left-section {
    display: flex;
    justify-content: left;
    align-items: center;
}