.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
  }

.dot-flashing {
    position: relative;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #8F8FA3;
    color: #8F8FA3;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
.dot-flashing::before {
    left: -10px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #8F8FA3;
    color: #8F8FA3;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}
  
.dot-flashing::after {
    left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #8F8FA3;
    color: #8F8FA3;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
      background-color: #8F8FA3;
    }
    50%,
    100% {
      background-color: #E3E3E8;
    }
}