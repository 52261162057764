.bridge-substep-card {
    width: 100%;
    border-radius: 8px;
    padding: 16px;
}

.bridge-substep-card2 {
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    margin: 32px 0;
    border: 1px solid #F1F2F3;
    background-color: #FFF;
}

.bridge-substep-card2:hover {
    cursor: pointer;
    background-color: #F1F2F3 !important;
}

.bridge-substep-card2:hover .bridge-substep-not-selected-radio {
    background: linear-gradient(90deg, #FF4D86 0%, #E926C3 100%);
    border: none;
}

.bridge-substep-card-header {
    display: flex;
    justify-content: left;
    align-items: center;
}

.bridge-substep-selected-radio {
    width: 16px;
    height: 16px;
    border-radius: 999px;
    /* background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%); */
    margin-right: 13.33px;
    border: 4px solid #FF4D86;
}

.bridge-substep-not-selected-radio {
    width: 16px;
    height: 16px;
    border-radius: 999px;
    background: white;
    border: 1px solid #2E3338;
    margin-right: 13.33px;
}

.bridge-tx-dropdown-selector-box {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
    margin-top: 8px;
}

.bridge-tx-dropdown-selector-box:hover {
    cursor: pointer;
}

.bridge-dropdown.tx-full-dd {
    width: 504px;
}

.bridge-tx-box-info {
    margin: 16px 0 8px 0;
}

.bridge-tx-input-box {
    background-color: white;
}

.bridge-done-card-info {
    margin: 16px 0 0 0;
}

@media(max-width: 1299px) {
    .bridge-dropdown.tx-full-dd {
        width: 70%;
    }
}

.bridge-scan-button {
    width: 355px;
    margin: auto;
    height: 48px;
}

@media(max-width: 400px) {
    .bridge-scan-button {
        width: 100%;
    }
}

.bridge-transaction-box {
    width: 100%;
    height: 72px;
    border: 1px solid #F1F2F3;
    border-radius: 8px;
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bridge-transaction-box-left-section {
    display: flex;
    justify-content: left;
    align-items: center;
}

.bridge-transaction-box-asset-icon {
    width: 32px;
    height: 32px;
    border-radius: 999px;
    margin-right: 12px;
}

.bridge-transaction-box-arrow-right-icon {
    width: 20px;
    height: 20px;
    border-radius: 999px;
}

.bridge-transaction-box-blockchain-icon {
    width: 24px;
    height: 24px;
    margin: 0 12px;
}

.bridge-transaction-box-button {
    border: 1px solid #E926C3;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    text-align: center;
}

.bridge-transaction-box-button:hover {
    cursor: pointer;
    border: 1px solid #FF4D86;
}