.borrow-table-b-cell {
    width: 320px;
}

.borrow-table-s-cell {
   width: 128px;
}

.borrow-table-pool-name {
    display: flex;
    justify-content: left;
    align-items: center;
}

.borrow-table-pool-assets {
    display: flex;
    justify-content: left;
    align-items: center;
}

.borrow-table-b-icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
}

.borrow-table-s-icon {
    width: 20px;
    height: 20px;
    margin-right: 2px;
}