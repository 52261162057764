.sidebar-wrapper {
    width: 240px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    display: flex;
    justify-content: left;
}

.sidebar {
    width: 80px;
    height: 100%;
}

.sidebar-content {
    padding: 16px 8px;
}

.sidebar-logo-section {
    height: 184px;
    text-align: center;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.sidebar-logo {
    height: 84px;
    width: auto;
}

.sidebar-logo:hover {
    cursor: pointer;
}

.sidebar-menu {
    padding: 16px 0;
}

.sidebar-item {
    display: flex;
}

.sidebar-item-icon-section {
    width: 48px;
    height: 48px;
    margin: 0 20px;
}

.sidebar-item-icon-section:hover {
    cursor: pointer;
}

.sidebar-hover-section {
    height: 100%;
    padding: calc(204px + 8px) 0 0 0;
}

.sidebar-hover-menu-row {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 40px;
    margin: 0 0 8px 0;
}

.sidebar-hover-menu-selector-section {
    margin-left: -16px;
}

.sidebar-hover-menu-item-section {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 8px;
}

.sidebar-hover-menu-item {
    height: 40px;
    padding: 12px 16px;
    border-radius: 8px;
    margin-left: -1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-hover-menu-item-bullet {
    background: linear-gradient(90deg, #FDECFA 0%, #FFEBF1 100%);
    border-radius: 999px;
    padding: 4px 8px;
    margin-left: 8px;
}

.sidebar-menu-selector-section {
    margin-left: -8px;
    margin-top: -8px;
}