.stake-top-info-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
}

.stake-top-info-separator {
    width: 1px;
    height: 16px;
    background-color: #C7C7D1;
    margin: 0 24px;
}

@media(max-width: 1299px) {
    .stake-top-info-separator {
        height: 40px;
    }
}

@media(min-width: 1300px) {
    .stake-top-info-section {
        display: flex;
        justify-content: space-between;
    }
    
}

.stake-top-info-value {
    margin-left: 8px;
}

@media(max-width: 1299px) {
    .stake-top-info-value {
        margin-left: 0;
        margin-top: 8px;
    }
}

.stake-page-reward-claim-button-section {
    display: flex;
    justify-content: space-between;
}