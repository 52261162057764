.farm-other-pools-header {
    margin: 48px 0;
}

.farm-table-s-cell {
    width: 200px;
}

.farm-table-b-cell {
    width: 400px;
}

.farm-table-cell {
    width: 300px;
}

.farm-table-pool-name {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.farm-table-b-icon1 {
    width: 32px;
    height: 32px;
    border-radius: 999px;
}

.farm-table-b-icon2 {
    width: 32px;
    height: 32px;
    border-radius: 999px;
    margin-left: -16px;
}

.farm-table-pool-name-text {
    margin-left: 12px;
}

.farm-right {
    text-align: right;
}

.farm-table-blockchain-icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.farm-table-asset-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.farm-table-token-earned-cell {
    display: flex;
    justify-content: left;
    align-items: center;
}

@media(max-width: 1299px) {
    .farm-table-asset-icon {
        margin-left: 4px;
        margin-right: 0;
    }

    .farm-table-pool-name-text {
        margin-left: 4px;
    }

    .farm-table-blockchain-icon {
        margin-right: 4px;
    }
}