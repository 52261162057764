.disconnect-dropdown-wrapper {
    width: 100%;
    height: 100%;
    margin-left: 80px;
    position: fixed;
    z-index: 100;
}

.disconnect-dropdown-close-wrapper {
    width: 100%;
    height: 100%;
    margin-left: 80px;
    position: fixed;
}

@media(max-width: 1299px) {
    .disconnect-dropdown-wrapper {
        margin-left: 0;
    }
}

.disconnect-dropdown-layout {
    display: flex;
    justify-content: right;
    margin-right: calc(80px + (100% - 80px - 1200px)/2);
}

@media(max-width: 1299px) {
    .disconnect-dropdown-layout {
        margin-right: 0;
        left: 16px;
        bottom: 100px;
        position: fixed;
    }
}

.disconnect-dropdown {
    position: absolute;
    width: 288px;
    padding: 18px 24px;
    border-radius: 8px;
    right: calc(80px + (100% - 80px - 1200px)/2);
    z-index: 100000;
}

@media(max-width: 1299px) {
    .disconnect-dropdown {
        left: 0;
        bottom: 0px;
    }
}

.disconnect-dropdown-on-scroll {
    width: 272px;
    padding: 18px 24px;
    border-radius: 8px;
    margin-top: 64px;
    margin-right: 16px;
}

.disconnect-dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #F1F2F3;
}

.disconnect-dropdown-header-left-section {
    display: flex;
    justify-content: left;
    align-items: center;
}

.disconnect-dropdown-header-left-section-text-section {
    margin-left: 8px;
}

.disconnect-dropdown-slippage-section {
    padding: 16px 0;
    border-bottom: 1px solid #F1F2F3;
}

.disconnect-dropdown-slippage-title {
    margin-bottom: 8px;
}

.disconnect-dropdown-slippage-subtitle {
    margin: 8px 0 0 0;
    text-align: right;
}

.disconnect-dropdown-slippage-input-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.disconnect-dropdown-slippage-input-label, .disconnect-dropdown-slippage-text-input-label {
    width: 80px;
    height: 32px;
    border-radius: 999px;
    border: 1px solid #F1F2F3;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 0 0 8px;
}

@media(max-width: 1299px) {
    .disconnect-dropdown-slippage-input-label, .disconnect-dropdown-slippage-text-input-label {
        width: 60px;
        margin: 0 0 0 2px;
    }
}

.disconnect-dropdown-slippage-input-label:hover {
    background-color: #F1F2F3;
}

.disconnect-dropdown-add-tokens-section {
    padding: 16px 0;
    border-bottom: 1px solid #F1F2F3;
}

.disconnect-dropdown-add-token-buttons-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
}

.disconnect-dropdown-add-token-button {
    width: 110px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #E926C3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disconnect-dropdown-add-token-button:hover {
    cursor: pointer;
    border: 1px solid #FF4D86;
}

.disconnect-button {
    padding: 16px 0 0 0;
    cursor: pointer;
}

.disconnect-button-layout {
    display: flex;
    justify-content: left;
}

.disconnect-button-text {
    margin-left: 8px;
}