@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

/* Typography */
.displayXL {
    font-family: 'DM Sans', sans-serif;
    font-size: 160px;
    font-weight: 400;
    line-height: 160px;
}

.displayL {
    font-family: 'DM Sans', sans-serif;
    font-size: 100px;
    font-weight: 400;
    line-height: 100px;
}

.displayM {
    font-family: 'DM Sans', sans-serif;
    font-size: 72px;
    font-weight: 700;
    line-height: 72px;
}

.headingXXXL {
    font-family: 'DM Sans', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 54px;
}

.headingXXL {
    font-family: 'DM Sans', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
}

.headingXL {
    font-family: 'DM Sans', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
}

.headingL {
    font-family: 'DM Sans', sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
}

.headingM {
    font-family: 'DM Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
}

.headingS {
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.headingXS {
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.paragraphXL {
    font-family: 'DM Sans', sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
}

.paragraphL {
    font-family: 'DM Sans', sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
}

.paragraphM {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}

.paragraphS {
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
}

.paragraphXS {
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.labelLRegular {
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}

.labelLBold {
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.labelMRegular {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}

.labelMBold {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
}

.labelSRegular {
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
}

.labelSBold {
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
}

.labelXSRegular {
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.labelXSBold {
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

/* Colors */
.magentaText1 {
    color: #E926C3;
}

.pinkText2 {
    color: #FF4D86;
}

.magentaBg1 {
    background-color: #E926C3;
}

.pinkBg2 {
    background-color: #FF4D86;
}

.gradientText {
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradientText:hover {
    background: linear-gradient(90deg, #EB3DC9 0%, #FF6697 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradientBg {
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
}

.gradientBg:hover {
    background: linear-gradient(90deg, #EB3DC9 0%, #FF6697 100%);
}

.succesText {
    color: #34C796;
}

.succesText:hover {
    color: #2EB286;
}

.succesBg {
    background: #EBFAF5;
}

.warningText {
    color: #FF8000;
}

.warningText:hover {
    color: #E57300;
}

.warningBg {
    background-color: #FFF5EB;
}

.dangerText {
    color: #FF5330;
}

.dangerText:hover {
    color: #FF5330;
}

.dangerBg {
    background-color: #FFEEEB;
}

.infoText {
    color: #0097C9;
}

.infoText:hover {
    color: #0082AD;
}

.infoBg {
    background-color: #E5F9FF;
}

.primaryText {
    color: #2E3338;
}

.secondaryText {
    color: #65657B;
}

.disabledText {
    color: #8F8FA3;
}

.disabledBg {
    background-color: #5C5C70;
    opacity: 0.32;
}

.bg {
    background-color: #F1F2F3;
}

.whiteText {
    color: #FFF;
}

.whiteBg {
    background-color: #FFF !important;
}

.transparentBg {
    background-color: #FFF;
    opacity: 0.32;
}

.elevationLow {
    box-shadow: 0px 4px 16px 0px #5C5C701F;
}

.elevationMedium {
    box-shadow: 0px 8px 36px 0px #5C5C702B;
}

.elevationHigh {
    box-shadow: 0px 16px 48px 0px #5C5C7038;
}

.wrapper {
    margin-left: 80px;
    padding-bottom: 128px;
}

@media(max-width: 1299px) {
    .wrapper {
        margin-left: 0;
        padding-bottom: 0;
        max-height: 100vh;
        width: 100vw;
        overflow-y: scroll;
        margin-top: 64px;
        margin-bottom: 64px;
    }
}

.content {
    width: 1200px;
    margin: auto;
}

@media(max-width: 1299px) {
    .content {
        width: calc(100vw - 16px - 16px);
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 0;
        margin-bottom: 72px;
    }
}

.button {
    border-radius: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button:hover {
    cursor: pointer;
}

.body {
    min-height: 100vh;
    width: 100%;
    background-color: #F1F2F3;
    background: linear-gradient(90deg, rgba(241,242,243,1) 4%, rgba(251, 218, 245, 0.7) 25%, rgba(254,226,219,0.7) 49%, rgba(251,251,218,0.7) 77%, rgba(241,242,243,1) 100%);
}

@media(max-width: 1299px) {
    .body {
        min-height: 100vh;
        width: 100%;
        background-color: #F1F2F3;
        background: linear-gradient(90deg, #F1F2F3 1%, #F1F2F3 100%);
    }
}

.headerSection {
    width: 100%;
    height: 86px;
    margin: 32px 0;
    padding: 0 0 16px 0;
    text-align: center;
}

@media(max-width: 1299px) {
    .headerSection {
        height: auto;
    }
}

.popupWrapper {
    position: fixed;
    top: 72px;
    left: 80px;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

@media(max-width: 1299px) {
    .popupWrapper {
        left: 0;
        bottom: 100px;
        top: calc(72px + 8px);
        height: calc(100vh - 100px - 72px - 8px);
    }
}

.popupBox {
    
}

@media(min-width: 1300px) {
    .layout {
        display: flex;
        justify-content: center;
    }
}

.leftSection {
    width: 738px;
    margin-right: 16px;
}

@media(min-width: 1300px) {
    .leftSection {
        animation-name: leftSectionAnimation;
        animation-duration: 0.75s;
    }
}

@media(max-width: 1299px) {
    .leftSection {
        width: 100%;
    }
}

@keyframes leftSectionAnimation {
    0%   {margin-right: 8px; margin-top: -10px}
    100% {margin-right: 16px; margin-top: 0px}
}

.greyBg {
    background-color: #E3E3E8;
}

@media(min-width: 1300px) {
    .cardsRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
    }
}

.card {
    width: 361px;
    height: 148px;
    border-radius: 8px;
    padding: 24px;
}

@media(max-width: 1299px) {
    .card {
        width: 100%;
        margin-bottom: 16px;
    }
}

.cardFullDim {
    width: 738px;
    height: 148px;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 16px;
}

@media(max-width: 1299px) {
    .cardFullDim {
        width: 100%;
    }
}

.cardFullDimRow {
    display: flex;
    justify-content: space-between;
}

.cardProgressBar {
    margin: 28px 0 4px 0;
}

.cardValueSection {
    display: flex;
    justify-content: left;
    margin-top: 4px;
}

.secondValue {
    margin-left: 8px;
}

.cardBullet {
    padding: 2px 4px;
    border-radius: 999px;
    display: inline-block;;
    justify-content: center;
    align-items: center;
    margin-top: 38px;
}

.rightSection {
    width: 430px;
    margin-left: 16px;
}

@media(min-width: 1300px) {
    .rightSection {
        animation-name: rightSectionAnimation;
        animation-duration: 0.75s;
    }
}

@media(max-width: 1299px) {
    .rightSection {
        width: 100%;
        margin-left: 0;
    }
}

@keyframes rightSectionAnimation {
    0%   {margin-left: 8px; margin-top: -10px}
    100% {margin-left: 16px; margin-top: 0px}
}

.inputCard {
    width: 100%;
    padding: 24px;
    border-top: 1px solid #E3E3E8;
    border-radius: 0 0 8px 8px;
}

@media(max-width: 1299px) {
    .inputCard {
        margin-bottom: 16px;
        position: relative;
    }
}

.inputCardHeader {
    display: flex;
}

.inputMenu {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
}

.inputMenuItem {
    width: 50%;
    height: 100%;
    padding: 12px 0;
    border-radius: 8px 0 0 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputMenuItem:hover {
    cursor: pointer;
}

.inputCardIcon:hover {
    cursor: pointer;
}

.inputCardTitle {
    width: 310px;
    margin: 0 36px 0 16px;
    text-align: center;
}

.inputHeader {
    display: flex;
    justify-content: space-between;
    padding: 26px 0 8px 0;
}

.inputBalanceSection, .inputBalanceSectionGrad {
    display: flex;
    justify-content: space-between;
}

.inputBalanceSectionGrad:hover {
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

.inputBalance {
    margin-left: 4px;
}

.inputBox {
    width: 100%;
    padding: 16px;
    border: 2px solid #F1F2F3;
    border-radius: 8px;
}

.inputBoxInactive {
    width: 100%;
    padding: 16px;
    border: 1px solid #E3E3E8;
    border-radius: 8px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
}

.flexInputBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputBox:hover{
    border: 2px solid #E926C3;
}

.inputButton {
    height: 48px;
    margin: 24px 0 0 0;
}

input {
    width: 100% !important;
    border: 1px solid white;
    background-color: white !important;
    font-family: DM Sans !important;
    color: #2E3338;
}

input:focus-visible {
    border: 0px !important;
    outline: 0px !important;
}

.orderDetailsSection {
    margin-top: 24px;
    margin-bottom: 6px;
}

.orderDetailsInfoBox {
    width: 100%;
    background-color: #F1F2F3;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
}

.orderDetailsInfoIcon {
    margin-top: -5px;
    margin-right: 8px;
}

.orderDetailsRow, .orderDetailsSubRow {
    display: flex;
    justify-content: space-between;
    margin: 6px 0;
}

orderDetailsSubRow {
    margin-left: 12px;
}

.orderDetailsSubRow {
    margin-left: 12px;
}

.orderDetailsFeesRow {
    display: flex;
    justify-content: right;
}

.orderDetailsFeesIcon {
    margin-top: -6px;
}

.orderDetailsFeesIcon:hover {
    cursor: pointer;
}

.subheaderSection {
    margin-top: 16px;
}

@media(min-width: 1300px) {
    .subheaderSection {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
    }
}

/* @media(max-width: 1299px) { */
    .mobSubheaderLinkSection {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
        margin-top: 16px;
    }
/* } */

@media(min-width: 1299px) {
    .mobSubheaderLinkSection {
        margin-top: 0;
    }
}

.subheaderMiddle {
    margin: 0 8px 0 16px;
}

.table {
    width: 100%;
    margin: auto;
    animation-name: tableAnimation;
    animation-duration: 0.5s;
}

@keyframes tableAnimation {
    0%   {width:90%}
    100% {width:100%}
}

.tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    padding: 0 16px;
}

.tableRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
    border-radius: 8px;
    background-color: white;
    margin: 4px 0;
}

.tableRow:hover {
    cursor: pointer;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
    z-index: 2;
    position: relative;
}

.dropdownButton {
    padding: 6px 8px;
}

.dropdownAssetIcon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}

.poolAssetDropdown {
    position: absolute;
    display: block;
    z-index: 1;
    width: 220px;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #5C5C701F;
    margin-left: 128px;
    margin-top: 220px;
}

@media(max-width: 1299px) {
    .poolAssetDropdown {
        margin-left: 80px;
    }
}

.poolAssetDropdownRow {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 40px;
    width: 100%;
    border-radius: 8px;
    padding: 0 16px;
}

.poolAssetDropdownRow:hover {
    background-color: #F1F2F3;
    cursor: pointer;
}

.poolAssetDropdownIcon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

@media(min-width: 1300px) {
    .singleCardLayout {
        display: flex;
        justify-content: left;
    }
}

.singleSCard {
    width: 584px;
    border-radius: 8px;
    padding: 24px;
}

@media(max-width: 1299px) {
    .singleSCard {
        width: 100%;
    }
}

.singleCardHeader {
    display: flex;
    justify-content: space-between;
}

.infoCard {
    width: 100%;
    border-radius: 8px;
    padding: 24px;
}

@media(max-width: 1299px) {
    .infoCard {
        margin-bottom: 16px;
    }
}

.mobTableCardHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.mobTableCardHeaderLeftSection {
    display: flex;
    justify-content: left;
    align-items: center;
}

.mobTableCardHeaderRightSection {
    display: flex;
    justify-content: right;
    align-items: center;
}

.mobTableCardRow {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.rangeInput {
    margin: 32px 0 0 0;
}

input[type="range"] {
    background-color: #F1F2F3 !important;
    border-radius: 999px;
}
  
input[type="range"]::-webkit-slider-thumb {
    
}

.cardBulletArrowIcon {
    width: 16px;
    height: 16px;
    margin: -2px 4px 0px 4px;
}

input[type="range"]::-moz-range-progress {
    
  }
  input[type="range"]::-moz-range-track {  
    
  }
  /* IE*/
  input[type="range"]::-ms-fill-lower {
 
  }
  input[type="range"]::-ms-fill-upper {  
    
  }

  input[type='range'] {
    overflow: hidden;
    width: 80px;
    -webkit-appearance: none;
    
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 15px;
    -webkit-appearance: none;
    margin-top: -1px;
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    height: 15px;
    border-radius: 999px;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 100%);
    box-shadow: -382px 0 0 374px #E926C3;
    cursor: pointer;
    border: 2px solid #FFF;
  }